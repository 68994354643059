import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_CURRENT_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR } from "./actionTypes"

export const getCurrentProfile = user => {
  return {
    type: GET_CURRENT_PROFILE,
    payload: { user }
  }
}

export const getCurrentProfileSuccess = user => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: { user },
  }
}

export const getCurrentProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  }
}

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
