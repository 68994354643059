import React, { useEffect, useState } from "react";
import {
    Button,
} from "reactstrap"

//i18n
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";

import {
    toggleUpdateWhitelistModal
} from "store/actions";

const UpdateWhitelistButton = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isUpdateWhitelistModalOpen } = useSelector((state) => ({
        isUpdateWhitelistModalOpen: state.Layout.modalUpdateWhitelist.isOpen,
    }))

    const setIsOpen = (isOpen) => {
        console.log("isOpen");
        (isOpen !== isUpdateWhitelistModalOpen ) && dispatch(toggleUpdateWhitelistModal(!isUpdateWhitelistModalOpen))
    }

    return (
        <div>
            <Button size="sm" color="primary" onClick={() => setIsOpen(true)}>{t('Edit')}</Button>
        </div>
    )
}

export default UpdateWhitelistButton