export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const TWO_AUTH_USER = "TWO_AUTH_USER"
export const TWO_AUTH_SUCCESS = "TWO_AUTH_SUCCESS"

export const USER_LOADED = "USER_LOADED"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"
