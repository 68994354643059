import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Alert,
    Button,
    Form,
    Modal,
    FormFeedback,
    Label,
    Input, Spinner, Toast, ToastBody, ToastHeader,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import {addNewInvestor, resetAddNewCustomer} from "store/actions";
import {addToast} from "../../store/toast/actions";
import {useNavigate} from "react-router-dom";
import ButtonSpinner from "./ButtonSpinner";


const NewCustomerModal = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            firstname: '',
            email: '',
            phone: '',
            roles: '',
            birthday: '',
            address: '',
            zipcode: '',
            city: '',
            comment: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t("Customer.pleaseEnterName")),
            firstname: Yup.string().required(t("Customer.pleaseEnterFirstname")),
            email: Yup.string().required(t("Customer.pleaseEnterMail")),
            phone: Yup.string().required(t("Customer.pleaseEnterPhone")),
            birthday: Yup.string().required(t("Customer.pleaseEnterBirthday")),
        }),
        onSubmit: (values) => {
          dispatch(addNewInvestor(values));
        }
    })

    const { addedCustomer, creationError, loading } = useSelector(state => ({
        creationError: state.customer.error,
        loading: state.customer.loading,
        addedCustomer: state.customer.addedCustomer,
    }));

   

    useEffect(() => {
        // When a new customer is added, close the modal
        if (addedCustomer) {
            setIsModalOpen(false)
            dispatch(addToast(
                t('Customer.newCustomerAddedToast.title'),
                t('Customer.newCustomerAddedToast.body', {customer: addedCustomer}),
                "info",
                "bx bx-user-plus",
            ))
            dispatch(resetAddNewCustomer())
            navigate('/customer/' + addedCustomer.id)
        }
    }, [addedCustomer])

    /*function showErrorMessage(error) {
        if (!error || !error.statusText) {
            return;
        }
        const errors = error.data.errors
        const errorsMessages = errors.map(error => error.path + " " + t(error.code))
        
        return (
            <Alert color="danger">
                {t(creationError.statusText.toString())}
                {
                    errorsMessages.map((error) => <div key={error}>- {error}</div>)
                }
            </Alert>
        )
    }*/

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#myModal"
            >
                <i className="align-middle bx bx-user-plus me-1 fs-3"/>
                {t("AddCustomer")}
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {t("AddCustomer")}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="name">{t("Customer.lastname")}</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder={t("Customer.enterName")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="firstname">{t("Customer.firstname")}</Label>
                                    <Input
                                        name="firstname"
                                        type="text"
                                        id="firstname"
                                        placeholder={t("Customer.enterFirstname")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.firstname || ""}
                                        invalid={
                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                        }
                                    />
                                    {validation.touched.firstname && validation.errors.firstname ? (
                                        <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="mail">{t("Email")}</Label>
                                    <Input
                                        name="email"
                                        type="email"
                                        id="email"
                                        placeholder={t("Customer.enterMail")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="phone">{t("Customer.phone")}</Label>
                                    <Input
                                        name="phone"
                                        type="tel"
                                        id="phone"
                                        placeholder={t("Customer.enterPhone")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phone || ""}
                                        invalid={
                                            validation.touched.phone && validation.errors.phone ? true : false
                                        }
                                    />
                                    {validation.touched.phone && validation.errors.phone ? (
                                        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="birthday">{t("Customer.birthday")}</Label>
                                    <Input
                                        name="birthday"
                                        type="date"
                                        id="birthday"
                                        placeholder={t("Customer.enterBirthday")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.birthday || ""}
                                        invalid={
                                            validation.touched.birthday && validation.errors.birthday ? true : false
                                        }
                                    />
                                    {validation.touched.birthday && validation.errors.birthday ? (
                                        <FormFeedback type="invalid">{validation.errors.birthday}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Label htmlFor="address">{t("Customer.address")}</Label><span className="fst-italic">{t("Customer.optionnal")}</span>
                            <Input
                                name="address"
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder={t("Customer.enterAddress")}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                    validation.touched.address && validation.errors.address ? true : false
                                }
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="zipcode">{t("Customer.zipcode")}</Label><span className="fst-italic">{t("Customer.optionnal")}</span>
                                    <Input
                                        name="zipcode"
                                        type="text"
                                        id="zipcode"
                                        placeholder={t("Customer.enterZipcode")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.zipcode || ""}
                                        invalid={
                                            validation.touched.zipcode && validation.errors.zipcode ? true : false
                                        }
                                    />
                                    {validation.touched.zipcode && validation.errors.zipcode ? (
                                        <FormFeedback type="invalid">{validation.errors.zipcode}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="city">{t("Customer.city")}</Label><span className="fst-italic">{t("Customer.optionnal")}</span>
                                    <Input
                                        name="city"
                                        type="text"
                                        id="city"
                                        placeholder={t("Customer.enterCity")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={
                                            validation.touched.city && validation.errors.city ? true : false
                                        }
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Label htmlFor="comment">{t("Customer.comment")}</Label><span className="fst-italic">{t("Customer.optionnal")}</span>
                            <Input
                                name="comment"
                                type="text"
                                className="form-control"
                                id="comment"
                                placeholder={t("Customer.enterComment")}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.comment || ""}
                                invalid={
                                    validation.touched.comment && validation.errors.comment ? true : false
                                }
                            />
                            {validation.touched.comment && validation.errors.comment ? (
                                <FormFeedback type="invalid">{validation.errors.comment}</FormFeedback>
                            ) : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                toggleModal();
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-primary w-md" >
                            {t("Create")}
                        </button>
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default NewCustomerModal
