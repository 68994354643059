/* FUND */
export const GET_CURRENT_FUND = "GET_CURRENT_FUND"
export const GET_CURRENT_FUND_SUCCESS = "GET_CURRENT_FUND_SUCCESS"
export const GET_CURRENT_FUND_FAIL = "GET_CURRENT_FUND_FAIL"
export const GET_SHARE_IN_FUND = "GET_SHARE_IN_FUND"
export const GET_SHARE_IN_FUND_SUCCESS = "GET_SHARE_IN_FUND_SUCCESS"
export const GET_SHARE_IN_FUND_FAIL = "GET_SHARE_IN_FUND_FAIL"
export const FETCH_MANAGERS = "FETCH_MANAGERS"
export const FETCH_MANAGERS_SUCCESS = "FETCH_MANAGERS_SUCCESS"
export const FETCH_MANAGERS_FAIL = "FETCH_MANAGERS_FAIL"
export const FETCH_INVESTORS = "FETCH_INVESTORS"
export const FETCH_INVESTORS_SUCCESS = "FETCH_INVESTORS_SUCCESS"
export const FETCH_INVESTORS_FAIL = "FETCH_INVESTORS_FAIL"
export const CREATE_NEW_FUND = "CREATE_NEW_FUND"
export const CREATE_NEW_FUND_SUCCESS = "CREATE_NEW_FUND_SUCCESS"
export const CREATE_NEW_FUND_FAIL = "CREATE_NEW_FUND_FAIL"

/* WHITELIST */
export const CREATE_NEW_WHITELIST = "CREATE_NEW_WHITELIST"
export const CREATE_NEW_WHITELIST_SUCCESS = "CREATE_NEW_WHITELIST_SUCCESS"
export const CREATE_NEW_WHITELIST_FAIL = "CREATE_NEW_WHITELIST_FAIL"