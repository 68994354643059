import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Alert,
    Button,
    Form,
    Modal,
    FormFeedback,
    Label,
    Input, Spinner, Toast, ToastBody, ToastHeader,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";


//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { addNewBlockchainAddress } from "store/actions"; //Penser à ajouter la fonction



const CustomerBlockchainAddressModal = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const address = props.address
    const name = props.name
    const ButtonName = props.ButtonName
    const ModalTitleLabel = props.ModalTitleLabel
    const ModalAddressLabel = props.ModalAddressLabel
    const ModalMessageNameField = props.ModalMessageNameField.toString()
    const ModalMessageAdressField = props.ModalMessageAdressField.toString()
    const UserPayementEntity = props.UserPayementEntity
    const tag = props.tag
    const blockchain = props.blockchain

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    const validation = useFormik({
       
        enableReinitialize: true,
        initialValues: {
            name: '',
            address: '',
            tag:'',
            payement_entity:'',
            blockchain:'',
        },
        
        validationSchema: Yup.object({
            name: Yup.string().required(ModalMessageNameField),
            address: Yup.string().required(ModalMessageAdressField),
            tag: Yup.string().required(tag),
            payement_entity: Yup.string().required(UserPayementEntity),
            blockchain: Yup.string().required(blockchain),

        }),
        onSubmit: (values) => {
            dispatch(addNewBlockchainAddress(values));
        }
    })

    /*const { addBlockchainAddress } = useSelector(state => ({
        addBlockchainAddress: state.blockchain_address.addBlockchainAddress,
    }));


    useEffect(() => {
        // When a new blockchain address is added, close the modal
        if (addBlockchainAddress) {
            setIsModalOpen(false)

        }
    }, [addBlockchainAddress])*/


    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#CustomerBlockchainAdressModal"
            >
                <i className="align-middle bx bx-user-plus me-1 fs-3" />
                {ButtonName}
            </Button>
            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="CustomerBlockchainAdressModalLabel">
                            {ButtonName}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="name">{ModalTitleLabel}</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder={ModalMessageNameField}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="address">{ModalAddressLabel}</Label>
                                    <Input
                                        name="address"
                                        type="text"
                                        id="address"
                                        placeholder={ModalMessageAdressField}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address || ""}
                                        invalid={
                                            validation.touched.address && validation.errors.address ? true : false
                                        }
                                    />
                                    {validation.touched.address && validation.errors.address ? (
                                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Input
                                        name="payement_user"
                                        type="hidden"
                                        id="payement_user"
                                        value={UserPayementEntity}

                                    />

                                    <Input
                                        name="tag"
                                        type="hidden"
                                        id="tag"
                                        value={tag}

                                    />
                                    <Input
                                        name="blockchain"
                                        type="hidden"
                                        id="blockchain"
                                        value={blockchain}

                                    />


                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                toggleModal();
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            {t("Close")}
                        </button>
                        <div>{"En attendant le payementEntity : " + UserPayementEntity}</div>
                        <button type="submit" className="btn btn-primary w-md" >
                            {t("Add")}
                        </button>
                    </div>

                </Form>

            </Modal>
        </React.Fragment>
    )

}

export default CustomerBlockchainAddressModal
