import React, { Component, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"

// datatable related plugins
import {
  createColumnHelper,
} from '@tanstack/react-table'

import { chain } from "mathjs"

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import Table from "components/Common/Table";
import { getWhitelists } from "store/actions";
import CreateWhitelistModal from "./CreateWhitelistModal"
import UpdateWhitelistModal from "./UpdateWhitelistModal"
import UpdateWhitelistButton from "./UpdateWhitelistButton"
import CreateFundButton from "./CreateFundButton"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min"

const WhitelistSection = () => {

  try {
    const dispatch = useDispatch();
    const { t } = useTranslation()
  
    const { whitelistList, activeAccount } = useSelector((state) => ({
      whitelistList: state.crypto.whitelists,
      activeAccount: state.crypto.activeAccount,
    }))
    // console.log("whitelistList", whitelistList);

    useEffect(() => {
        dispatch(getWhitelists())
    }, [])

    useEffect(() => {
      const fetchWhitlistInterval = setInterval(() => dispatch(getWhitelists()), 10000);
      return () => {
        clearInterval(fetchWhitlistInterval);
      };
    }, [])
      //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: whitelistList.length,
    custom: true,
  }
  const ProjetColumns = [
    {
      dataField: "name",
      text: 'Nom',
      sort: true,
    },
    {
      dataField: "tokens",
      text: 'Tokens',
      sort: true,
      formatter: function formatter(cellContent, row) {
        tokens => tokens.getValue().length > 0 ? tokens.getValue().map((token, key) => <span key={key} className="bg-light rounded-pill">{token}</span>) : null
      }
    },
    {
      dataField: "showDetails",
      isDummyField: true,
      text: 'Actions',
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <UpdateWhitelistButton />
      ),
    },
  ]

    const columnHelper = createColumnHelper()
    const whitelistColumns = [
      columnHelper.accessor('name', {
        id: 1,
        header: 'Nom',
        footer: info => info.column.id,
      }),
      columnHelper.accessor('tokens', {
        header: 'Tokens',
        cell: tokens => tokens.getValue().length > 0 ? tokens.getValue().map((token, key) => <span key={key} className="bg-light rounded-pill">{token}</span>) : null,
        footer: info => info.column.id,
      }),
      columnHelper.accessor('showDetails', {
        header: 'Actions',
        cell: ({ row }) => row.original.owner === activeAccount ? <UpdateWhitelistButton /> : null,
        footer: info => info.column.id,
      }),
    ]
    
    return (
        <>
        <Card>
            <CardBody>
                <CardTitle className="d-flex justify-content-between align-items-center">
                    {t('Whitelist')}
                    <CreateWhitelistModal />
                </CardTitle>
            {
                whitelistList.length > 0 ?
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProjetColumns}
                    data={whitelistList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={whitelistList}
                        columns={ProjetColumns}
                        bootstrap4
                      >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive mx-4">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                :
                  <div className="p-4">Aucune whitelist n'a été créée</div>
            }
            </CardBody>
        </Card>
        <UpdateWhitelistModal />
        </>
    )
  } catch (error) {
    console.log("error table:", error);
  }
}

export default WhitelistSection