import {
  GET_WALLET,
  GET_WALLET_FAIL,
  GET_WALLET_SUCCESS,
  GET_CRYPTO_ORDERS,
  GET_CRYPTO_ORDERS_FAIL,
  GET_CRYPTO_ORDERS_SUCCESS,
  GET_FUNDS,
  GET_FUNDS_SUCCESS,
  GET_FUNDS_FAIL,
  INIT_CONTRACTS,
  STORE_FUND_FACTORY_ABI,
  GET_WHITELISTS_SUCCESS,
  GET_WHITELISTS,
  GET_WHITELISTS_FAIL,
  CONNECT_WALLET,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_SUCCESS,
  SET_WEB3_DATAS,
  SET_WEB3_SUCCESS,
  SET_WEB3_FAIL,
  DISCONNECT_WALLET,
  GET_CURRENT_FUND,
  TOKEN_LIST,
  TOKEN_LIST_SUCCESS,
  TOKEN_LIST_FAIL
} from "./actionTypes"

export const setWeb3Datas = () => ({
  type: SET_WEB3_DATAS
})

export const setWeb3Success = () => ({
  type: SET_WEB3_SUCCESS
})

export const setWeb3Fail = error => ({
  type:SET_WEB3_FAIL,
  payload: error
})

export const initContractFail = () => ({
  type: INIT_CONTRACTS
})

export const getWallet = () => ({
  type: GET_WALLET,
})

export const getWalletSuccess = wallets => ({
  type: GET_WALLET_SUCCESS,
  payload: wallets,
})

export const getWalletFail = error => ({
  type: GET_WALLET_FAIL,
  payload: error,
})

export const getFunds = () => ({
  type: GET_FUNDS,
})

export const getFundsSuccess = funds => ({
  type: GET_FUNDS_SUCCESS,
  payload: funds,
})

export const getFundsFail = error => ({
  type: GET_FUNDS_FAIL,
  payload: error,
})

export const storeFundFactoryAbiSuccess = address => ({
  type: STORE_FUND_FACTORY_ABI,
  payload: address
})

export const getCryptoOrders = () => ({
  type: GET_CRYPTO_ORDERS,
})

export const getCryptoOrdersSuccess = orders => ({
  type: GET_CRYPTO_ORDERS_SUCCESS,
  payload: orders,
})

export const getCryptoOrdersFail = error => ({
  type: GET_CRYPTO_ORDERS_FAIL,
  payload: error,
})

export const getWhitelists = () => ({
  type: GET_WHITELISTS
})

export const getWhitelistsSuccess = list => ({
  type: GET_WHITELISTS_SUCCESS,
  payload: list
})

export const getWhitelistsFail = error => ({
  type: GET_WHITELISTS_FAIL,
  payload: error
})

export const connectWalllet = () => ({
  type: CONNECT_WALLET
})

export const connectWalletSucces = ({ activeAccount, balance, chainId, chainName }) => ({
  type: CONNECT_WALLET_SUCCESS,
  payload: { activeAccount, balance, chainId, chainName }
})

export const connectWalletFail = error => ({
  type: CONNECT_WALLET_FAIL,
  payload: error
})

export const disconectWallet = () => ({
  type: DISCONNECT_WALLET
})

export const apiTokens = () => ({
  type: TOKEN_LIST
})

export const apiTokensSuccess = tokens => ({
  type: TOKEN_LIST_SUCCESS,
  payload: tokens
})

export const apiTokensFail = error => ({
  type: TOKEN_LIST_FAIL,
  payload: error
})