import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import Fund from "pages/Fund/index"
import Market from "pages/Market";
import Favorites from "pages/Dashboard/Favorites";
import MyFunds from "pages/MyFund/MyFunds";


// Customer
import CustomerProfil from "pages/Customer/CustomerProfil";
import MyCustomers from "pages/Customer/MyCustomers"

// Others
import Wallet from "pages/Wallet"




const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/my-funds", component: <MyFunds /> },
  { path: "/market", component: <Market /> },

  //Profile
  { path: "/profile", component: <UserProfile /> },

  //Fund
  { path: "/fund/:fundAddress", component: <Fund />},

  //Wallet
  { path: "/wallet/:walletAddress", component: <Wallet />},


  //Customer
  { path: "/customer/:id", component: <CustomerProfil />},
  { path: "/customer", component: <MyCustomers />},
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { publicRoutes, authProtectedRoutes }
