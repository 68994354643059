
// LOGIN
export const POST_LOGIN = "/api/v1/authenticate"
export const GET_TWO_AUTH = "/api/v1/totp/qrcode"
export const POST_ENABLE_TWO_AUTH = "/api/v1/totp/enable"

// REGISTER
export const POST_REGISTER = "/api/v1/users"

// USER
export const GET_ME_INFORMATION = "/api/v1/me"
export const PATCH_ME_INFORMATION = "/api/v1/me"
export const PUT_ME_EMAIL = "/api/v1/me/email"
export const PATCH_ME_ADDRESS = "/api/v1/me/address"
export const GET_USERS = "/api/v1/users"
export const GET_INVESTORS = "/api/v1/users"
export const GET_INVESTOR = id => `/api/v1/users/${encodeURIComponent(id)}`
export const POST_CREATE_USER = "/api/v1/users"
export const POST_ACTIVATE = id => `/api/v1/users/${encodeURIComponent(id)}/activate`

// WALLET
export const GET_WALLET = "/api/v1/wallets"
export const POST_WALLET = "/api/v1/wallets"
export const POST_SEARCH_WALLET = "/api/v1/wallets/search"
export const GET_WALLET_DETAIL = "/api/v1/wallets"


export const GET_TOKENS_LIST = "https://api-polygon-tokens.polygon.technology/tokenlists/default.tokenlist.json"

//BLOCKCHAIN_ADDRESS

export const GET_BLOCKCHAIN_ADDRESSES = "/api/v1/blockchain_addresses"
export const POST_CREATE_BLOCKCHAIN_ADDRESS = "/api/v1/blockchain_addresses"
export const POST_SEARCH_BLOCKCHAIN_ADDRESSES = "/api/v1/blockchain_addresses/search"
export const GET_BLOCKCHAIN_ADDRESSES_DETAIL = "/api/v1/blockchain_addresses"