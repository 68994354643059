import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Label,
    Input,
} from "reactstrap";

//i18n
import { useTranslation } from "react-i18next"
import {useResource} from "../Crud/hooks";
import {useSelector} from "react-redux";
import {filterObject} from "../../helpers/object_utils";
import ButtonSpinner from "./ButtonSpinner";

const UpdateEarningModal = props => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const resource = useResource(
        `/api/v1/users/${props.customer}/incomes`,
        '',
        'updateEarningModal',
        {one: ''},
    )
    const [value, setValue] = useState({})
    const item = useSelector(resource.selectItem())
    const loading = useSelector(resource.selectItemLoading())
    const updateLoading = useSelector(resource.selectUpdateItemLoading())

    useEffect(() => {
        setValue({})

        if (props.customer) {
            resource.loadItem()
        } else {
            resource.resetItem()
        }
    }, [props.customer])

    useEffect(() => {
        if (item) {
            const newValue = {
                professionalIncomes: item?.professional_incomes,
                realEstateIncomes: item?.real_estate_incomes,
                securitiesIncomes: item?.securities_incomes,
                pensionAnnuityIncome: item?.pension_annuity_income,
                annualLoanCharge: item?.annual_loan_charge,
                servedPensionsCharge: item?.served_pensions_charge,
            };

            props.onLoad?.(newValue)
            setValue(newValue)
        }
    }, [item])


    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen)
        removeBodyCss();
    }

    function handleChange(event) {
        setValue(current => ({...current, [event.target.name]: event.target.value}))
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#myModal"
                outline={props.minimal}
            >
                <i className={"fas fa-pen" + (props.minimal ? "" : "me-2")}/>
                { props.minimal ? "" : <span>{t("Edit")}</span> }
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t("Customer.updateEarning")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-name">{t("Customer.professionnalIncome")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-name"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"professionalIncomes"}
                                        value={value?.professionalIncomes || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname">{t("Customer.propertyIncome")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-firstname"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"realEstateIncomes"}
                                        value={value?.realEstateIncomes || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.incomeFromSecurities")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"securitiesIncomes"}
                                        value={value?.securitiesIncomes || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.pensions")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"pensionAnnuityIncome"}
                                        value={value?.pensionAnnuityIncome || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.annualBorrowingCharge")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"annualLoanCharge"}
                                        value={value?.annualLoanCharge || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.pensionServed")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"servedPensionsCharge"}
                                        value={value?.servedPensionsCharge || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={toggleModal}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        {t("Close")}
                    </button>

                    <button
                        type="button"
                        disabled={loading || !item}
                        onClick={() => {
                            props.onSubmit?.(value)
                            resource.update(item.id, filterObject({
                                professional_incomes: value.professionalIncomes,
                                real_estate_incomes: value.realEstateIncomes,
                                securities_incomes: value.securitiesIncomes,
                                pension_annuity_income: value.pensionAnnuityIncome,
                                annual_loan_charge: value.annualLoanCharge,
                                served_pensions_charge: value.servedPensionsCharge,
                            }))
                            setIsModalOpen(!isModalOpen)
                        }}
                        className="btn btn-primary w-md">
                        {updateLoading ? <ButtonSpinner /> : t("Update")}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

UpdateEarningModal.propTypes = {
    customer: PropTypes.string.isRequired,
    onLoad: PropTypes.func,
    minimal: PropTypes.any,
    onSubmit: PropTypes.func,
}

export default UpdateEarningModal
