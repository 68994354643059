import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Alert,
    Button,
    Form,
    Modal,
    FormFeedback,
    Label,
    Input, Spinner, Toast, ToastBody, ToastHeader,
} from "reactstrap";

//import usdcImage from "/src/assets/icons/usdc-coin.svg";
import imgEmpty from "assets/images/empty.svg"
import UsdcIcon from "assets/icons/usdc-coin.svg"

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next"



const ButtonInvestInFund = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);


    const { isInvestModalOpen } = useSelector((state) => ({
        isInvestModalOpen: ''
    }))

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    const ModalTitle = props.ModalTitle


    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#InvestFundModal"
            >
                <i className="align-middle bx bx-money me-1 fs-3" />
                {ModalTitle}
            </Button>
            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >

                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="InvestFundModal">
                            {ModalTitle}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col xl="12">
                                <div>
                                    <p>
                                        Choisissez le montant à investir
                                    </p>
                                </div>
                            </Col>
                            <Col xl="8">
                                <label Nameclass="d-flex justify-content-between"><span class="fw-medium">Montant</span><span>0 USDC</span></label>
                                <Input
                                    name="InvestAmount"
                                    type="text"
                                    id="InvestAmount"
                                    placeholder="Montant de l'investissement"
                                />
                            </Col>
                            <Col xl="4">
                                <div className="d-flex align-items-center gap-2 pt-4"><img src={UsdcIcon} className="avatar-xs" /><span>USDC</span></div>
                            </Col>
                            <Col xl="12">
                                <div className="help-block pt-2">La somme à investir doit être inférieur ou égale au solde d'USDC</div>
                            </Col>
                            <Col xl="12">
                                <div className="row mb-2 align-items-end pt-4">
                                    <div className="col-7 fw-medium">Frais de dépot fond</div>
                                    <div className="col-5 col-sm-4 fw-medium text-end">0 USDC</div>
                                    <div className="col-12 text-secondary">0 %</div>
                                </div>
                                <div className="row mb-2 align-items-end pt-4">
                                    <div className="col-7 fw-medium">Frais de dépot protocol</div>
                                    <div className="col-5 col-sm-4 fw-medium text-end">0 USDC</div>
                                    <div className="col-12 text-secondary">0 %</div>
                                </div>
                                <div className="row mb-2 align-items-end pt-4">
                                    <div className="col-7 fw-medium">Total</div>
                                    <div className="col-5 col-sm-4 fw-medium text-end">0 USDC</div>
                                    <div className="col-12 text-secondary">0 %</div>
                                </div>
                                <div class="col-12">
                                <div class="form-check">
                                <label data-v-cd703ea2="" for="isTermsAccepted" className="form-check-label">J'accepte les termes et conditions.</label>
                                <Input
                                    name="isTermsAccepted"
                                    type="checkbox"
                                    for="isTermsAccepted"
                                    id="isTermsAccepted"
                                    placeholder="Montant de l'investissement"
                                    className="form-check-input"
                                />

                                
                                </div></div>
                            </Col>
                            <Col xl="12">
                                <div className="row">
                                    <div className="col-6 mb-2">
                                        <button disabled="disabled" className="col btn btn-primary w-100 text-nowrap"> Approuver envoi </button>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <button disabled="disabled" className="col btn btn-primary w-100 text-nowrap"> Investir </button>
                                    </div>

                                
                                </div>
                            </Col>
                            

                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                toggleModal();
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-primary w-md" >
                            {t("Add")}
                        </button>
                    </div>

                </Form>

            </Modal>
        </React.Fragment>
    )
}

export default ButtonInvestInFund