import PropTypes from "prop-types";

export const columnsShape = PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    sort: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    translatable: PropTypes.bool,
}))

export const rowActionsShape = PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.any,
    title: PropTypes.string,
    callback: PropTypes.func,
    translatable: PropTypes.bool,
    hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}))
