import { chain } from "mathjs"

export const getChainName = (chainId) => {
    const chainIdLowerCase = chainId.toLowerCase()
    let chainName = ""

    switch(chainIdLowerCase) {
        case "0x1":
        chainName = "Mainnet";
        break;
        case "0x2a":
        chainName = "Kovan";
        break;
        case "0x3":
        chainName = "Ropsten";
        break;
        case "0x4":
        chainName = "Rinkeby";
        break;
        case "0x5":
        chainName = "Goerli";
        break;
        case "0x89":
            chainName = "Polygon"
            break;
        case "0x13881": // 80001 Mumbay Polygon testnet
            chainName = "Mumbay"
            break;
        case "0xa4b1":
            chainName = "Arbitrum One"
            break;
        case "0x539": // 1337 (often used on localhost)
        case "0x7a69": // 31337 (localhost)
        case "0x1691": // 5777 (default in Ganache)
        default:
            chainName = "Localhost";
            break;
    }
    return chainName
}

export const formatToken = (balance, decimal) => {
    if (balance > 0) {
        return chain(balance.toString()).divide(10**decimal).done()
    }
    return 0
}
