import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    TabContent,
    TabPane,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Input,
    Progress,
} from "reactstrap"
import classnames from "classnames";

//i18n
import { useTranslation } from "react-i18next"
// Formik validation
import * as Yup from "yup";
import { useFormik, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import StepTitle from "components/Common/StepTitle";
import {
    createNewFund,
    getWhitelists,
    toggleNewFundModal
} from "store/actions";
import { ModalFooter } from "react-bootstrap";



const CreateFundModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [showWthitelists, setShowWthitelists] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1])
    const [progress, setProgress] = useState(12.5)

    const { whitelistList, createFundPending, isNewFundModalOpen } = useSelector((state) => ({
        whitelistList: state.crypto.whitelists,
        createFundPending: state.Fund.createFundPending,
        isNewFundModalOpen: state.Layout.modalNewWallet.isOpen,
    }))

    const toggleModal = (isOpen) => {
        if (isOpen) {
            setActiveTab(1)
            setShowWthitelists(false)
            setPassedSteps([1])
            setProgress(12.5)
        }
        (isOpen !== isNewFundModalOpen ) && dispatch(toggleNewFundModal(!isNewFundModalOpen))
    }

    const initialValues = {
        fundName: '',
        fundSymbol: '',
        managementFee: 0,
        entryFee: 0,
        selectedWhitelist: null,
        termsAccepted: false,
    }
    const validationSchema = Yup.object({
        fundName: Yup.string().required(t("Required first name")),
        fundSymbol: Yup.string().required(t("Required last name")),
        managementFee: Yup.number(),
        entryFee: Yup.number()
        // termsAccepted: Yup.boolean("Vous devez acceptez les termes et conditions").oneOf([true], "Vous devez acceptez les termes et conditions"),
    })

    const submitFunction = (values, actions) => {
        if (activeTab === 4) {
            if (values.termsAccepted) {
                const fundToSend = {
                    tokenName: values.fundName,
                    tokenSymbol: values.fundSymbol,
                    fundManagmentFees: values.managementFee,
                    fundPaymentFees: values.entryFee,
                    useWhitelist: showWthitelists
                }
                if (values.selectedWhitelist) {
                    fundToSend.idWhitelist = values.selectedWhitelist.id
                } else {
                    fundToSend.idWhitelist = 0
                }
                dispatch(createNewFund(fundToSend))
                actions.setSubmitting(false)
            }
        } else {
            actions.setSubmitting(false)
            toggleTab(activeTab + 1)
        }
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab]
        if (tab >= 1 && tab <= 4) {
            setProgress((tab * 25) - 12.5)
            setActiveTab(tab)
            setPassedSteps(modifiedSteps)
        }
        }
    }

    const isNumber = evt => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    const toggleList = (isChecked) => {
        dispatch(getWhitelists())
        setShowWthitelists(isChecked)
    }

    return (
        <Modal centered={true} isOpen={isNewFundModalOpen} toggle={toggleModal} unmountOnClose={true}>
            <Formik
                initialValues={initialValues}
                onSubmit={submitFunction}
                validationSchema={validationSchema}
            >
            { props =>
            <Form onSubmit={props.handleSubmit} className="form-wizard md">
            <ModalHeader toggle={toggleModal}>
            {t("Creat a product")}
            </ModalHeader>
            <ModalBody>
                <div className="wizard-navigation">
                    <Progress
                        value={progress}
                        color="primary"
                        className="wizard-progress-with-circle"
                    />
                    <ul className="wizard-nav wizard-nav-pills">
                        <StepTitle
                            currentStep={1}
                            props={props}
                            activeTab={activeTab}
                            passedSteps={passedSteps}
                            label={t('Creat fund Modal base step')}
                        />
                        <StepTitle
                            currentStep={2}
                            props={props}
                            activeTab={activeTab}
                            passedSteps={passedSteps}
                            label={t('Creat fund Modal whitelist step')}
                        />
                        <StepTitle
                            currentStep={3}
                            props={props}
                            activeTab={activeTab}
                            passedSteps={passedSteps}
                            label={t('Creat fund Modal fees step')}
                        />
                        <StepTitle
                            currentStep={4}
                            props={props}
                            activeTab={activeTab}
                            passedSteps={passedSteps}
                            label={t('Creat fund Modal summary step')}
                        />
                    </ul>
                    <div className="wizard-tab-content">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                                            <div>
                                                <FormGroup>
                                                {/*Data Web2 Only*/}
                                                    <Label for="productCategory">{t('Product category')}</Label>

                                                    {props.touched.productCategory && props.errors.productCategory ? (
                                                        <FormFeedback type="invalid">{props.errors.productCategory}</FormFeedback>
                                                    ) : null}

                                                    <div className="form-control p-0 position-relative overflow-hidden rounded">
                                                    <select class="form-select" >
                                                                <option value="individualProduct">{t('Individual product')}</option>
                                                                <option value="collectifProduct" selected>{t('Collective product')}</option>
                                                                <option value="treasuryProduct">{t('Treasury product')}</option>
                                                    </select>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                {/*Data Web2 Only*/}
                                                    <Label for="productDescription">{t('Description')}</Label>
                                                    <Input
                                                        id="productDescription"
                                                        name="productDescription"
                                                        placeholder=""
                                                        type="textarea"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.productDescription || ""}
                                                        invalid={
                                                            props.touched.productDescription && props.errors.productDescription ? true : false
                                                        }
                                                    />
                                                    {props.touched.productDescription && props.errors.productDescription ? (
                                                        <FormFeedback type="invalid">{props.errors.productDescription}</FormFeedback>
                                                    ) : null}

                                                    </FormGroup>
                                                <FormGroup>
                                                    <Label for="fundName">{t('Fund Name')}</Label>
                                                    <Input
                                                        id="fundName"
                                                        name="fundName"
                                                        placeholder={t('Fund Name')}
                                                        type="text"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.fundName || ""}
                                                        invalid={
                                                            props.touched.fundName && props.errors.fundName ? true : false
                                                        }
                                                    />
                                                    {props.touched.fundName && props.errors.fundName ? (
                                                        <FormFeedback type="invalid">{props.errors.fundName}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="fundSymbol">{t('Fund symbol')}</Label>
                                                    <Input
                                                        id="fundSymbol"
                                                        name="fundSymbol"
                                                        placeholder={t('Fund symbol')}
                                                        type="text"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.fundSymbol || ""}
                                                        invalid={
                                                            props.touched.fundSymbol && props.errors.fundSymbol ? true : false
                                                        }
                                                    />
                                                    {props.touched.fundSymbol && props.errors.fundSymbol ? (
                                                        <FormFeedback type="invalid">{props.errors.fundSymbol}</FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="fundDenominationToken">
                                                        {t('Fund denomination token')}
                                                    </Label>
                                                    <div>{t('Description of denomination token')}</div>
                                                    <span className="badge bg-warning mb-2">Réglage permanent</span>
                                                    <Input
                                                        id="fundDenominationToken"
                                                        name="fundDenominationToken"
                                                        placeholder="Le token de dénomination du fond"
                                                        type="text"
                                                        value="USDC"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                            </TabPane>

                            <TabPane tabId={2}>
                                <div className="mb-3">
                                    <div className="form-check form-switch form-switch-md">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            // bsSize="lg"
                                            onChange={(e) => toggleList(e.target.checked)}
                                        />
                                        <Label
                                            className="form-check-label"
                                            for="flexSwitchCheckChecked"
                                        >
                                            Utiliser une whitelist
                                        </Label>
                                    </div>
                                    <div>Le gestionnaire de fond peut choisir de ne pouvoir utiliser que les tokens présent dans une whitelist de son choix.</div>
                                </div>
                                {
                                showWthitelists &&
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <FormGroup>
                                            <Label for="tokenSearch">Chercher un token</Label>
                                            <Input 
                                                id="tokenSearch"
                                                name="tokenSearch"
                                                placeholder="Rechercher"
                                                type="text"
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="form-control p-0 position-relative overflow-hidden rounded">
                                        <div
                                            className="overflow-auto"
                                            style={{"height": "190px", willChange: "transform", direction: "ltr"}}
                                        >
                                            {
                                                whitelistList.map((whitelist, key) =>
                                                    <div
                                                        key={key}
                                                        className={classnames("px-2 pt-1 pb-2 list-line", { "list-line-selected": whitelist === props.values.selectedWhitelist})}
                                                        // props.values.selectedWhitelist = whitelist
                                                        onClick={() => props.setFieldValue("selectedWhitelist", whitelist)}
                                                    >
                                                        <div className="text-truncate fw-medium">
                                                            {whitelist.name}
                                                        </div>
                                                        <div>
                                                            <div className="d-flex gap-2 overflow-hidden">
                                                                {
                                                                whitelist.tokens.length > 0 && 
                                                                    whitelist.tokens.map((token, keyToken) =>
                                                                        <span key={keyToken} className="bg-secondary bg-soft rounded-pill">
                                                                            {token}
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                }
                            </TabPane>
                            
                            <TabPane tabId={3}>
                                <div>
                                    <p>Le gestionnaire du fond peut facturer plusieurs types de frais, qui sont tous payés en USDC du fond.</p>
                                </div>
                                <div>
                                    <FormGroup>
                                        <Label for="entryFee">Frais d'entrée</Label>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            placeholder="0.0"
                                            step="0.01"
                                            min="0"
                                            max="99"
                                            id="entryFee"
                                            name="entryFee"
                                            onKeyPress={isNumber}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.entryFee || ""}
                                            invalid={
                                                props.touched.entryFee && props.errors.entryFee ? true : false
                                            }
                                        />
                                        {props.touched.entryFee && props.errors.entryFee ? (
                                            <FormFeedback type="invalid">{props.errors.entryFee}</FormFeedback>
                                        ) : null}
                                        {/* <span className="help-block" v-if="$v.form.fees.entryFee.$error && !$v.form.fees.entryFee.decimal">Les frais d'entrée ne peuvent pas être négatifs</span>
                                        <span className="help-block" v-if="$v.form.fees.entryFee.$error && !$v.form.fees.entryFee.between">Les frais d'entrée ne peuvent pas être négatifs ou supérieur à 99%</span> */}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="managementFee">Frais de gestion</Label>
                                        <Input
                                            className="form-control"
                                            type="number"
                                            placeholder="0.0"
                                            step="0.01"
                                            min="0"
                                            max="99"
                                            id="managementFee"
                                            name="managementFee"
                                            onKeyPress={isNumber}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.managementFee || ""}
                                            invalid={
                                                props.touched.managementFee && props.errors.managementFee ? true : false
                                            }
                                        />
                                        {props.touched.managementFee && props.errors.managementFee ? (
                                            <FormFeedback type="invalid">{props.errors.managementFee}</FormFeedback>
                                        ) : null}
                                        {/* <span className="help-block" v-if="$v.form.fees.managementFee.$error && !$v.form.fees.managementFee.decimal">Les frais de gestion ne peuvent pas être négatifs</span>
                                        <span className="help-block" v-if="$v.form.fees.managementFee.$error && !$v.form.fees.managementFee.between">Les frais de gestion ne peuvent pas être négatifs ou supérieur à 99%</span> */}
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="fw-medium">Les frais de service du protocole</div>
                                        <div>Frais fixes mesurés en pourcentage annuel du total des actifs sous gestion. Les frais de gestion s'accumulent continuellement et sont automatiquement payés à chaque dépôt et rachat.</div>
                                        <span className="badge bg-warning mb-2">Réglage permanent</span>
                                        <Input className="form-control" type="text" value="0,5% par an" disabled />
                                    </FormGroup>    
                                    <FormGroup>
                                        <div className="fw-medium">Les frais sur versement du protocole</div>
                                        <span className="badge bg-warning mb-2">Réglage permanent</span>
                                        <Input className="form-control" type="text" value="0%" disabled />
                                    </FormGroup>
                                </div>
                            </TabPane>
                            
                            <TabPane tabId={4}>
                                <Row>
                                    <Col>
                                        <div className="mb-3 mx-n2">
                                            <div className="p-2 rounded-2 bg-light">
                                                <div>Veuillez examiner attentivement la configuration du fond avant de le créer.</div>
                                                <div className="fw-bold">Tous les paramètres de configuration sont permanents</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <h4>Les basics</h4>
                                        <Row>
                                            <Col className="col-7">Nom du fond</Col>
                                            <Col className="col-5">{props.values.fundName}</Col>
                                        </Row>

                                        <Row>
                                            <Col className="col-7">Symbole du fond</Col>
                                            <Col className="col-5">{props.values.fundSymbol}</Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col className="col-7">Actif de dénomination</Col>
                                            <Col className="col-5">USDC</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="col-lg-12">
                                        <h4>Les frais</h4>
                                        <Row>
                                            <Col className="col-7">Frais d'entrée</Col>
                                            <Col className="col-5">{props.values.entryFee}%</Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-7">Frais de gestion</Col>
                                            <Col className="col-5">{props.values.managementFee}%</Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-7">Les frais de service du protocole</Col>
                                            <Col className="col-5">0,6% par an</Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-7">Les frais sur versement du protocole</Col>
                                            <Col className="col-5">0%</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div v-if="form.whitelist.useWhitelist && form.whitelist.selected" className="row mb-3">
                                    <Col className="col-lg-12">
                                        <h4>Whitelist</h4>
                                        <Row>
                                            <Col className="col-7">Whitelist utilisée</Col>
                                            <Col className="col-5">{ props.values.selectedWhitelist && props.values.selectedWhitelist.name }</Col>
                                        </Row>
                                    </Col>
                                </div>
                                <Row className="mb-3">
                                    <h6>Termes et conditions</h6>
                                    <Row className="mb-1">
                                        <Col className="col-12">
                                            <textarea
                                                className="w-100 border rounded-2"
                                                disabled={true}
                                                id="terms"
                                                readOnly=""
                                                rows="4"
                                                defaultValue="En utilisant ce logiciel, vous comprenez, reconnaissez et acceptez que Hesiode et/ou le logiciel
                                                sous-jacent sont fournis 'tels quels' et sans garantie ou représentation d'aucune sorte, expresse ou implicite.
                                                Toute utilisation de ce logiciel open source publié sous la licence publique générale GNU version 3 (GPL 3) se
                                                fait à vos risques et périls dans toute la mesure permise par la loi applicable, toute responsabilité ainsi que
                                                toutes les garanties, y compris toute adéquation à un usage usage particulier en ce qui concerne Hesiode
                                                et/ou le logiciel sous-jacent et l'utilisation de ceux-ci sont rejetés.
                                            "
                                            >
                                            </textarea>   
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <Input
                                                type="checkbox"
                                                name="termsAccepted"
                                                id="termsAccepted"
                                                className="me-2"
                                                value={props.values.termsAccepted || false}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                            <Label for="termsAccepted">J'accepte les termes et conditions.</Label>
                                            
                                            {props.touched.termsAccepted && props.errors.termsAccepted ? (
                                            <FormFeedback type="invalid">{props.errors.termsAccepted}</FormFeedback>
                                            ) : null}
                                            {
                                            props.values.termsAccepted && props.isSubmitting &&
                                                <div
                                                    className="help-block"
                                                    v-if="$v.form.termsAccepted.$error && !$v.form.termsAccepted.checked"
                                                >
                                                    Vous devez acceptez les termes et conditions
                                                </div>
                                            }
                                    </Col>
                                    </Row>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="wizard-card-footer">
                {/* <div className="wizard-card-footer clearfix"> */}
                        <div className="wizard-footer-left previous">
                        { 
                            activeTab > 1 &&
                                <Button
                                    type="button"
                                    outline
                                    color="primary"
                                    className="wizard-btn wizard-footer-left"
                                    onClick={() => {
                                        toggleTab(activeTab - 1)
                                    }}
                                >
                                    Previous
                                </Button>
                        }
                        </div>
                    <div className={classnames("wizard-footer-right", activeTab === 4 ? "next disabled" : "next")}>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={props.isSubmitting || createFundPending || (!props.values.termsAccepted && activeTab === 4)}
                            className="wizard-btn wizard-footer-right"
                        >
                            {activeTab === 4 ? "Submit" : "Next"}
                        </Button>
                    </div>
                {/* </div> */}
            </ModalFooter>
            </Form>
            }
            </Formik>
        </Modal>
    )
}

export default CreateFundModal