import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Label,
    Input,
} from "reactstrap";
import Select from "react-select"

//i18n
import { useTranslation } from "react-i18next"

const UpdateCustomerPersonnalInformationModal = () => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCustomer, setNewCustomer] = useState({ name: "", description: "", maritalStatus: null})

    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    const maritalStatusOptions = [
        { label: t("MaritalStatus.single"), value: "1" },
        { label: t("MaritalStatus.married"), value: "2" },
        { label: t("MaritalStatus.divorced"), value: "3" },
        { label: t("MaritalStatus.widowed"), value: "4" },
    ]
    
    const updateName = (event) => {
        setNewCustomer(current => ({ ...current, name: event.target.value}))
    }
    const updateDescription = (event) => {
        setNewCustomer(current => ({ ...current, description: event.target.value}))
    }
    const updateMaritalStatus = (value) => {
        setNewCustomer(current => ({ ...current, maritalStatus: value}))
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#myModal"
            >
                <i className="align-middle fas fa-pen me-2"/>
                {t("Edit")}
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t("AddCustomer")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-name">{t("Customer.lastname")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-name"
                                        placeholder={t("Customer.enterName")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname">{t("Customer.firstname")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname"
                                        placeholder={t("Customer.enterFirstname")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-mail">{t("Email")}</Label>
                                    <Input
                                        type="email"
                                        className="form-control"
                                        id="formrow-mail"
                                        placeholder={t("Customer.enterMail")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-phone">{t("Customer.phone")}</Label>
                                    <Input
                                        type="tel"
                                        className="form-control"
                                        id="formrow-phone"
                                        placeholder={t("Customer.enterPhone")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-birthday">{t("Customer.birthday")}</Label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-birthday"
                                        placeholder={t("Customer.enterBirthday")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-birthday">{t("Customer.maritalStatus")}</Label>
                                    <Select
                                        classNamePrefix="select2-selection"
                                        title="maritalStatus"
                                        options={maritalStatusOptions}
                                        id="formrow-name"
                                        placeholder={t("Customer.selectMaritalStatus")}
                                        value={newCustomer.maritalStatus}
                                        onChange={s => {updateMaritalStatus(s)}}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Label htmlFor="formrow-address">{t("Customer.address")}</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="formrow-address"
                                placeholder={t("Customer.enterAddress")}
                            />
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-zipcode">{t("Customer.zipcode")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-zipcode"
                                        placeholder={t("Customer.enterZipcode")}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-city">{t("Customer.city")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-city"
                                        placeholder={t("Customer.enterCity")}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Label htmlFor="formrow-comment">{t("Customer.comment")}</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="formrow-comment"
                                placeholder={t("Customer.enterComment")}
                            />
                        </div>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        {t("Close")}
                    </button>
                    <button type="submit" className="btn btn-primary w-md">
                        {t("Create")}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateCustomerPersonnalInformationModal