import {
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames";

const StepTitle = ({currentStep, props, activeTab, passedSteps, label}) => {
    const showIcon = (currentStep, isValid, passedSteps) => {
        if (currentStep === activeTab && !isValid) {
            return <i className="wizard-icon fas fa-exclamation-circle"></i>
        } else if ((passedSteps || []).includes(currentStep)) {
            return <i className="wizard-icon mdi mdi-checkbox-marked-circle-outline"></i>
        } else {
            return <i className="wizard-icon far fa-circle"></i>
        }
    }

    return (
    <NavItem className={classnames({ current: activeTab === currentStep })}>
        <NavLink
            className={classnames({ current: activeTab === currentStep })}
            disabled={!(passedSteps || []).includes(currentStep)}
        >
            <div className={
                classnames("wizard-icon-circle md", {
                    "has_error": !props.isValid && activeTab === currentStep,
                    "checked": (passedSteps || []).includes(currentStep),
                    "current": props.isValid && activeTab === currentStep
                })
            }>
                { showIcon(currentStep, props.isValid, passedSteps) }
            </div>
            <span className={classnames("stepTitle", {
                "has_error": !props.isValid && activeTab === currentStep,
                "current": activeTab === currentStep
            })}>{label}</span>
        </NavLink>
    </NavItem>
    )
}

export default StepTitle