import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  TWO_AUTH_USER,
  TWO_AUTH_SUCCESS,
  API_ERROR, USER_LOADED,
} from "./actionTypes"

export const loginUser = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  }
}

export const twoAuthUser = (username, password, code2FA, navigate) => {
  return {
    type: TWO_AUTH_USER,
    payload: { username, password, code2FA, navigate },
  }
}

export const loginSuccess = (token, navigate) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { token, navigate},
  }
}

export const logoutUser = navigate => {
  return {
    type: LOGOUT_USER,
    payload: { navigate },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const twoAuthSuccess = (token, navigate) => {
  return {
    type: TWO_AUTH_SUCCESS,
    payload: { token, navigate },
  }
}

export const userLoaded = (user) => {
  return {
    type: USER_LOADED,
    payload: { user },
  }
}

export const apiError = (error, translate = false, resetToken = false) => {
  return {
    type: API_ERROR,
    payload: { error, translate, resetToken },
  }
}
