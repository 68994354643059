import { ethers } from 'ethers'
import FundFactory from "../../contracts/FundFactory.json";
import FundLogic from "../../contracts/FundLogic.json";
import ERC20 from "../../contracts/USDC.json";
import addresses from "../../contracts/addresses.json";
import axios from "axios"

import { call, put, takeEvery, select, take } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_WALLET,
  GET_CRYPTO_ORDERS,
  GET_FUNDS,
  GET_WHITELISTS,
  CONNECT_WALLET,
  SET_WEB3_DATAS,
  TOKEN_LIST
} from "./actionTypes"
import {
  getWalletSuccess,
  getWalletFail,
  getCryptoOrdersSuccess,
  getCryptoOrdersFail,
  getFundsSuccess,
  getFundsFail,
  getWhitelistsFail,
  getWhitelistsSuccess,
  connectWalletFail,
  connectWalletSucces,
  setWeb3Fail,
  setWeb3Success,
  disconectWallet,
  apiTokensSuccess,
  apiTokensFail
} from "./actions"

//Include Both Helper File with needed methods
import { getWallet, getCryptoOrder } from "helpers/fakebackend_helper"
import { getChainName } from 'utils';
import {
  getTokensList
} from "helpers/fire_api_helper"

const MANAGER_ROLE = "0xaf290d8680820aad922855f39b306097b20e28774d6c1ad35a20325630c3a02c";

const FACTORY_CONTRACT_NAME = "FundFactory"
const FUND_CONTRACT_NAME = "FundLogic"

//////////////
//    UTILS
//////////////
const getBalance = async (provider, address) => {
  return await provider.getBalance(address);
}

//////////////
//    SAGA
//////////////
function* setWeb3Datas () {
  try {
    const { ethereum } = window
    if (!ethereum) {
        yield put(setWeb3Fail("MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"))
        return
    }

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    ethereum.autoRefreshOnNetworkChange = false;

    const web3Provider = new ethers.providers.Web3Provider(ethereum)
    const { provider } = web3Provider

    yield call(provider.request, { method: 'eth_accounts' })
    const activeAccount = provider.selectedAddress
    const balance = activeAccount? yield call(getBalance, web3Provider, activeAccount) : 0;
    
    if (activeAccount) {
      yield put(connectWalletSucces({
        activeAccount,
        balance,
        chainId: ethereum.chainId,
        chainName: getChainName(ethereum.chainId)
      }))
    } else {
      yield put(disconectWallet())
    }

    yield put(setWeb3Success())
  } catch (error) {
    console.error(error);
    yield put(setWeb3Fail(error))
  }
}

function* fetchWallet() {
  try {
    const response = yield call(getWallet)
    yield put(getWalletSuccess(response))
  } catch (error) {
    yield put(getWalletFail(error))
  }
}

function* fetchCrypto() {
  try {
    const response = yield call(getCryptoOrder)
    yield put(getCryptoOrdersSuccess(response))
  } catch (error) {
    yield put(getCryptoOrdersFail(error))
  }
}

const fetchFundsFromChain = async (fundFactoryAddress) => {
  if (!fundFactoryAddress) {
    return []
  }

  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)

  const fundFactory = new ethers.Contract(fundFactoryAddress, FundFactory.abi, web3Provider);

  const fundlist = await fundFactory.getFundsList()
  
  if (Array.isArray(fundlist) && fundlist.length > 0) {
    let fundListWithObject = []
    
    for (const fundAddress of fundlist) {
        const fundLogic = new ethers.Contract(fundAddress, FundLogic.abi, web3Provider);
        const [name, symbol, totalSupply, owner, feeBpsForFund, feeDepositForFund, feeBpsForProtocol, feeDepositForProtocol, assetBalance] = await fundLogic.getFundInformations()

        const fund = {
          fundAddress: fundAddress.toLowerCase(),
          name,
          symbol,
          totalSupply,
          owner: owner.toLowerCase(),
          feeBpsForFund: feeBpsForFund / 100 + "%",
          feeDepositForFund: feeDepositForFund / 100 + "%",
          feeBpsForProtocol: feeBpsForProtocol / 100 + "%",
          feeDepositForProtocol: feeDepositForProtocol / 100 + "%",
          assetBalance
        }
        fundListWithObject = [...fundListWithObject, fund]
    }
    return fundListWithObject

  }
  return []
}

const fetchWhitelistsFromChain = async (fundFactoryAddress) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)
  
  const protocol = new ethers.Contract(fundFactoryAddress, FundFactory.abi, web3Provider)
  
  const [ids, names, owners, tokensCounts, tokensNames] = await protocol.getWhiteLists()

  let list = []
  
  tokensCounts.forEach((count, index) => {
    list[index] = {}
    list[index].tokens = []
    list[index].id = ids[index]
    list[index].name = names[index]
    list[index].owner = owners[index].toLowerCase()
    list[index].tokensCount = count
    
    if (tokensNames[index]) {
      list[index].tokens = tokensNames[index].split(",").sort()
    } else {
      list[index].tokens = []
    }
  });

  return list
}

function* fetchFunds() {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }

    const chainId = parseInt(ethereum.chainId)
    const fundFactoryAddress = addresses[FACTORY_CONTRACT_NAME][chainId];

    const funds = yield call(fetchFundsFromChain, fundFactoryAddress)
    
    yield put(getFundsSuccess(funds))
  } catch (error) {
    yield put(getFundsFail(error))
  }
}

function* fetchWhitelists() {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }

    const chainId = parseInt(ethereum.chainId)

    if (chainId) {
      const fundFactoryAddress = addresses[FACTORY_CONTRACT_NAME][chainId];
      
      const whitelists = yield call(fetchWhitelistsFromChain, fundFactoryAddress)
      
      yield put(getWhitelistsSuccess(whitelists))
    }
  } catch (error) {
    yield put(getWhitelistsFail(error))
  }}

function* connectWallet() {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }
    
    yield call(ethereum.request, { method: 'eth_requestAccounts' })
    
    const activeAccount = ethereum.selectedAddress

    if (activeAccount) {
      yield call( )
    }
  } catch (error) {
    console.error("error", error);
    yield put(connectWalletFail(error))
  }
}

function* tokenList( ) {
  try {
    // https://tokens.coingecko.com/uniswap/all.json
    // const url = "https://tokens.coingecko.com/uniswap/all.json"
    const reponse = yield call(getTokensList);
    const tokens = reponse.tokens.sort((a, b) => a.symbol > b.symbol)//.map(token => ({ value: token, text: token.symbol }))
    yield put(apiTokensSuccess(tokens))
  } catch (error) {
    yield put(apiTokensFail(error))
      console.error("error get tokens", error);
  }
}

function* cryptoSaga() {
  yield takeEvery(GET_WALLET, fetchWallet)
  yield takeEvery(GET_CRYPTO_ORDERS, fetchCrypto)
  yield takeEvery(GET_FUNDS, fetchFunds)
  yield takeEvery(GET_WHITELISTS, fetchWhitelists)
  yield takeEvery(CONNECT_WALLET, connectWallet)
  yield takeEvery(SET_WEB3_DATAS, setWeb3Datas)
  yield takeEvery(TOKEN_LIST, tokenList)
}

export default cryptoSaga
