import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {useResource} from "../../components/Crud/hooks";
import FundTable from "../../cruds/fund/FundTable";
import {Card, CardBody} from "reactstrap";
import Filters from "../../cruds/fund/Filters";

function Market(props) {
    const resource = useResource('/api/v1/funds', '/fond', 'marketPage')

    return (
        <div className={"page-content"}>
            <div className={"mb-2 d-flex justify-content-between align-items-center"}>
                <h4>{props.t('Funds list')}</h4>
            </div>
            <Card>
                <CardBody>
                    <Filters resource={resource} />
                    <FundTable resource={resource} />
                </CardBody>
            </Card>
        </div>
    )
}

Market.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Market)
