import {
    ADD_BLOCKCHAIN_ADDRESS_SUCCESS,
    ADD_BLOCKCHAIN_ADDRESS_FAIL,
    ADD_NEW_BLOCKCHAIN_ADDRESS,
} from "./actionTypes"

export const addBlockchainAddressSuccess = address => ({
    type: ADD_BLOCKCHAIN_ADDRESS_SUCCESS,
    payload: address,
})

export const addBlockchainAddressFail = error => ({
    type: ADD_BLOCKCHAIN_ADDRESS_FAIL,
    payload: error,
})

export const addNewBlockchainAddress = address => ({
    type: ADD_NEW_BLOCKCHAIN_ADDRESS,
    payload: address,
  })
  