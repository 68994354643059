import { call, put, takeEvery, select } from "redux-saga/effects"
import {
    ADD_NEW_BLOCKCHAIN_ADDRESS,

} from "./actionTypes"

import {
    addBlockchainAddressSuccess,
    addBlockchainAddressFail,

} from "./actions"

import {
    createNewBlockchainAddress,
} from "helpers/fire_api_helper"

import { selectAccessToken } from "store/auth/login/selector"


function* onAddNewBlockchainAddress({ payload: address }) {
    const accessToken = yield select(selectAccessToken)

    try {
        
        const newBlockchainAddress = {
            name: address.name,
            address: address.address,
            tag: address.tag,
            payement_entity: address.payement_entity,
            blockchain: address.blockchain,
        }
        const response = yield call(createNewBlockchainAddress, newBlockchainAddress, accessToken)

        yield put(addBlockchainAddressSuccess(response))
    } catch (error) {
        yield put(addBlockchainAddressFail(error))
    }
}

function* BlockchainAddressSaga() {
    yield takeEvery(ADD_NEW_BLOCKCHAIN_ADDRESS, onAddNewBlockchainAddress)

}

export default BlockchainAddressSaga
