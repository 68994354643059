import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"

// datatable related plugins
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { chain } from "mathjs"

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import Table from "components/Common/Table";
import { Link } from "react-router-dom";
import { getFunds } from "store/actions";
import ConnectWalletModal from "components/web3/ConnectWalletModal";
import CreateFundButton from "components/web3/CreateFundButton";
import WhitelistSection from "components/web3/WhitelistSection";

import imgEmpty from "assets/images/empty.svg"


//NewCustomerModal
import NewCustomerModal from "components/Common/NewCustomerModal";
import { getCustomers } from "store/actions"

const Dashboard = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { customers, onGetCustomers } = props

  useEffect(() => {
    onGetCustomers()
}, [onGetCustomers])



  const { fundsList, isLoadingFunds, isWalletConnected } = useSelector((state) => ({
    fundsList: state.crypto.funds,
    isLoadingFunds: state.crypto.isLoading,
    isWalletConnected: state.crypto.isWalletConnected,
  }))
 
  
  const DashboardReports = [
    { title: t("Customers"), iconClass: "bx-user", data: customers.length, currency: "", link:"/customer" },
    { title: t("Aum"), iconClass: "bxs-bank", data: "140 643", currency: "$", link:"/customer" },
    { title: t("Products"), iconClass: "bxs-cube", data: "5", currency: "", link:"/product" },
    { title: t("Wallet.Balance"), iconClass: "bx-money", data: "2 354", currency: "$", link:"/wallet" },
  ]

  useEffect(() => {
    dispatch(getFunds())
  }, [])

  useEffect(() => {
    const fetchFundsInterval = setInterval(() => dispatch(getFunds()), 10000);
    
    return () => {
      clearInterval(fetchFundsInterval);
    };
  }, [])

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor('name', {
      header: 'Nom',
      footer: info => info.column.id,
    }),
    columnHelper.accessor('symbol', {
      header: 'Symbole',
      footer: info => info.column.id,
    }),
    columnHelper.accessor('fundAddress', {
      header: 'Addresse',
      cell: address => <span className="font-monospace">{address.getValue()}</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('assetBalance', {
      header: 'USDC',
      cell: assetBalance => assetBalance.getValue() > 0 ? chain(assetBalance.getValue().toString()).divide(10**6).done() : 0,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('totalSupply', {
      header: 'Nombre de parts',
      cell: totalSupply => totalSupply.getValue() > 0 ? chain(totalSupply.getValue().toString()).divide(10**18).done() : 0,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('feeBpsForFund', {
      header: 'Frais de gestion',
      footer: info => info.column.id,
    }),
    columnHelper.accessor('feeDepositForFund', {
      header: "Frais d'entrée",
      footer: info => info.column.id,
    }),
    columnHelper.accessor('showDetails', {
      header: 'Voir plus',
      cell: ({ row }) => <Link to={`/fund/${row.original.fundAddress}`}>Details</Link>,
      footer: info => info.column.id,
    }),
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('Dashboard')} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <h4>{t('Dashboards')}</h4>
            <div>
            <NewCustomerModal />
          </div>
          </div>
          <Row>
            {/* Reports Render */}
            {
                DashboardReports.map((report, key) => (
                <Col sm="6" xxl="3" key={"_col_" + key}>
                <Link to={report.link}>
                <Card>
                    <CardBody>
                      <div className="mini-stats-wid">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.data + report.currency}</h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                        </div>
                        
                    </CardBody>
                  </Card>
                </Link>
                  
                </Col>
                
              ))
            }
          </Row>
         
          
          
          {
            fundsList.length > 0 ?
            <Row className="mb-4">
              <Col>
                <Card>
                  <CardBody>
                  <CardTitle className="d-flex justify-content-between align-items-center">
                  {t('Product list')}
                   
                </CardTitle>
                    <Row>
                      <Col>
                      </Col>
                    </Row>
                    <Table data={fundsList} columns={columns} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            :
            (
              isLoadingFunds ?
              <Row className="mb-4">
                <div className="display-6 text-center mb-4">Chargement des fonds...</div>
              </Row>
              :
              <Row className="mb-4">
                <Col className="text-center">
                  <Row className="justify-content-center">
                    <Col xs="6" sm="4" md="5" lg="3">
                      <img src={imgEmpty} className="" />
                    </Col>
                  </Row>
                  <div className="display-6 mb-4">Il n'y a pas encore de fond existant</div>
                  <div className="d-flex justify-content-center">
                  {
                    isWalletConnected ?
                      <CreateFundButton />
                    :
                      <ConnectWalletModal />
                  }
                  </div>
                </Col>
              </Row>
            )
          }
          <Row>
            <Col>
                <WhitelistSection />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

//export default Dashboard;


Dashboard.propTypes = {
  customers: PropTypes.any,
  onGetCustomers: PropTypes.func,
}

const mapStateToProps = state => ({ ...state.customer })

const mapDispatchToProps = dispatch => ({
  onGetCustomers: () => dispatch(getCustomers()),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)