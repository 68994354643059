import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {useResource} from "../../components/Crud/hooks";
import FundTable from "../../cruds/fund/FundTable";
import {Card, CardBody} from "reactstrap";
import Filters from "../../cruds/fund/Filters";
import {useSelector} from "react-redux";
import {selectConnectedUser} from "../../store/auth/login/selector";

function MyFunds(props) {
    const resource = useResource('/api/v1/funds', '/fond', 'myFundsPage')
    const me = useSelector(selectConnectedUser)

    return (
        <div className={"page-content"}>
            <div className={"mb-2 d-flex justify-content-between align-items-center"}>
                <h4>{props.t('My funds')}</h4>
            </div>
            <Card>
                <CardBody>
                    <Filters resource={resource} />
                    <FundTable
                        resource={resource}
                        initFilters={{owner: [me.id]}}
                        missingFundMessage={props.t('You have not created any fund')}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

MyFunds.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(MyFunds)
