import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

// datatable related plugins
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Table from "components/Common/Table";

//i18n
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import classnames from "classnames";
import { isGoodNetworkSelector } from "store/crypto/selector";
import {
    fetchInvestors,
    fetchManagers,
    fetchShareInFund,
    getCurrentFund
} from "store/actions";

import { Form, useParams } from "react-router-dom";
import {
    getFormatedSharesBalance,
    getFormatedTotalSupply,
    getFundBalanceEth
} from "store/fund/selector";

import usdcIcon from "assets/icons/usdc-coin.svg"
import ButtonInvestInFund from "components/web3/ButtonInvestInFund"

const Fund = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { fundAddress } = useParams()

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(getCurrentFund(fundAddress))
        dispatch(fetchShareInFund(fundAddress))
        dispatch(fetchManagers(fundAddress))
        dispatch(fetchInvestors(fundAddress))
    }, [])

    const formatedSharesBalance = useSelector(getFormatedSharesBalance)
    const formatedTotalSupply= useSelector(getFormatedTotalSupply)
    const isGoodNetwork = useSelector(isGoodNetworkSelector)
    const fundBalanceEth = useSelector(getFundBalanceEth)
    const { isWalletConnected, activeAccount, currentFund, sharesBalance, getCurrentManagers, getCurrentInvestors } = useSelector((state) => ({
        isWalletConnected: state.crypto.isWalletConnected,
        activeAccount: state.crypto.activeAccount,
        currentFund: state.Fund.currentFund,
        sharesBalance: state.Fund.sharesBalance,
        getCurrentManagers: state.Fund.currentManagers,
        getCurrentInvestors: state.Fund.currentInvestors,
    }))

    console.log(currentFund);

    const columnHelper = createColumnHelper()
    const managersColumns = [
        columnHelper.accessor('manager', {
          id: 1,
          header: 'Manager',
          cell: ({ row }) => <div className="fs-5 font-monospace">{ row.original }</div>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('actions', {
            header: 'Actions',
            cell: ({ row }) => (
                    row.original === activeAccount
                && currentFund.owner === activeAccount
                && <Button size="sm" color="primary">
                        <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>                               
                        Supprimer
                    </Button>
            ),
            footer: info => info.column.id,
        }),
    ]
    const investorsColumns = [
        columnHelper.accessor('investor', {
          id: 1,
          header: 'Investisseur',
          cell: ({ row }) => <div className="fs-5 font-monospace">{ row.original }</div>,
          footer: info => info.column.id,
        }),
        columnHelper.accessor('actions', {
          header: 'Actions',
          cell: ({ row }) => (
                  row.original === activeAccount
              && currentFund.owner === activeAccount
              && <Button size="sm" color="primary">
                      <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>                               
                      Supprimer
                  </Button>
          ),
          footer: info => info.column.id,
        }),
    ]

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>{t('FundDetails')} | Hesiode</title>
      </MetaTags>
      <Container fluid>
      <div className="mb-2 d-flex justify-content-between align-items-center">
            <h4>{t('Dashboards')}</h4>
            <div>
            <ButtonInvestInFund  ModalTitle="Investir dans le fond" />
          </div>
          </div>
        <Row>
            <Col>
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "1",
                            })}
                            onClick={() => {
                                toggleTab("1");
                            }}
                        >
                            <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Détail</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "2",
                            })}
                            onClick={() => {
                                toggleTab("2");
                            }}
                        >
                            <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Managers</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "3",
                            })}
                            onClick={() => {
                                toggleTab("3");
                            }}
                        >
                            <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Investisseurs</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "4",
                            })}
                            onClick={() => {
                                toggleTab("4");
                            }}
                        >
                            <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Portefeuille</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div>
                                                    <h2>{ currentFund.name }</h2>
                                                </div>
                                                <div className="d-inline-block p-2 rounded-2 bg-light font-monospace">
                                                { currentFund.fundAddress }
                                                </div>
                                            </div>
                                            {
                                            isGoodNetwork && isWalletConnected &&
                                                <div className="align-self-center d-flex gap-2">
                                                    {/* <withdraw-modal v-if="this.getSharesBalance > 0" />
                                                    <invest-in-fund-modal /> */}
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="4">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">Total investi</p>
                                                <h5 className="mb-0">
                                                    { fundBalanceEth } USDC
                                                </h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="4">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                        {
                                        formatedSharesBalance > 0 ?
                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">Vos parts / Nombre de part</p>
                                                <h5 className="mb-0">
                                                    { formatedSharesBalance } / { formatedTotalSupply }
                                                </h5>
                                            </div>
                                        :
                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">Nombre de part</p>
                                                <h5 className="mb-0">
                                                    { formatedTotalSupply }
                                                </h5>
                                            </div>
                                        }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            
                            <Col sm="4">
                                <Card>
                                    <CardBody className="card-body">
                                        <div className="d-flex">
                                            <div className="me-3 align-self-center">
                                                <img
                                                    src={usdcIcon}
                                                    alt="polygon token"
                                                    className="avatar-xs"
                                                />
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">Token de dénomination</p>
                                                <h5 className="mb-0">
                                                    USDC
                                                </h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <p className="card-title">
                                                Manager{ getCurrentManagers.length > 1 && "s" }
                                            </p>
                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            {
                                                getCurrentManagers.map((manager, index) => (
                                                    <div key={index} className="d-inline-block p-2 rounded-2 bg-light font-monospace">
                                                        {manager}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <p className="card-title">
                                                Investisseur{ getCurrentInvestors.length > 1 && "s"}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            {
                                                getCurrentInvestors.map((investor, index) => (
                                                    <div key={investor} className="d-inline-block p-2 rounded-2 bg-light font-monospace">
                                                        { investor }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="2" >
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div>
                                                <h3>Liste des managers</h3>
                                            </div>
                                            {/* <add-manager-modal
                                                v-if="getActiveAccount && getActiveAccount === currentFund.owner"
                                                className="mb-3"
                                                :managers-list="getCurrentManagers"
                                            /> */}
                                        </div>
                                        <Table data={getCurrentManagers} columns={managersColumns} />
                                        {/* <b-table
                                            striped hover
                                            :items="managersList"
                                            :fields="tableManagersProperties.fields"
                                            responsive="sm"
                                            :per-page="tableManagersProperties.itemsPerPage"
                                            :current-page="tableManagersProperties.currentPage"
                                            :filter="tableManagersProperties.filter"
                                            :filter-included-fields="tableManagersProperties.filterOn"
                                            @filtered="fundFiltered"
                                        >
                                            <template #cell(manager)="data">
                                                <div className="fs-5 font-monospace">
                                                    {{ data.item.manager}}
                                                </div>
                                            </template>
                                            <template #cell(actions)="data">
                                                <b-button
                                                    v-if="data.item.manager !== currentFund.owner && getActiveAccount && getActiveAccount === currentFund.owner"
                                                    variant=""
                                                    size="sm"
                                                    @click="confirmDeleteManager(data.item.manager)"
                                                >
                                                    <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>
                                                    Supprimer
                                                </b-button>
                                            </template>
                                        </b-table> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="3">
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div>
                                                <h3>Liste des investisseurs autorisés</h3>
                                            </div>
                                            {/* <!-- <add-manager-modal
                                                v-if="getActiveAccount && getActiveAccount === currentFund.owner"
                                                className="mb-3"
                                                :managers-list="getCurrentManagers"
                                            /> -->
                                            <add-investor-modal
                                                v-if="getActiveAccount && getActiveAccount === currentFund.owner"
                                                className="mb-3"
                                                :investors-list="getCurrentInvestors"
                                            /> */}
                                        </div>
                                        <Table data={getCurrentInvestors} columns={investorsColumns} />
                                        {/* <b-table
                                            striped hover
                                            :items="investorsList"
                                            :fields="tableInvestorsProperties.fields"
                                            responsive="sm"
                                            :per-page="tableInvestorsProperties.itemsPerPage"
                                            :current-page="tableInvestorsProperties.currentPage"
                                            :filter="tableInvestorsProperties.filter"
                                            :filter-included-fields="tableInvestorsProperties.filterOn"
                                        >
                                            <template #cell(investor)="data">
                                                <div className="fs-5 font-monospace">
                                                    {{ data.item.investor}}
                                                </div>
                                            </template>
                                            <template #cell(actions)="data">
                                                <b-button
                                                    v-if="data.item.investor !== currentFund.owner && getActiveAccount && getActiveAccount === currentFund.owner"
                                                    variant=""
                                                    size="sm"
                                                    @click="confirmDeleteInvestor(data.item.investor)"
                                                >
                                                    <i className="mdi mdi-trash-can-outline font-size-16 align-middle me-2"></i>
                                                    Supprimer
                                                </b-button>
                                            </template>
                                        </b-table> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="4">
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div>
                                                <h3>Liste des tokens</h3>
                                            </div>
                                            {/* <swap-tokens-modal
                                                v-if="isCurrentAccountManager"
                                                :fundAddress="currentFund.fundAddress"
                                                className="mb-3"
                                                :listFrom="listTokensFrom"
                                                :listTo="listTokensTo"
                                                @openModal="populateListForSwapModal"
                                            /> */}
                                        </div>
                                        {/* <b-table
                                            responsive="sm"
                                            hover
                                            :fields="tableTokensProperties.fields"
                                            :items="trackedAssets"
                                            table-className="align-middle"
                                        >
                                            <template #cell(name)="data">
                                                <div className="fs-5 font-monospace">
                                                    <div className="fw-bold fs-5">{{ data.item.symbol }}</div>
                                                    <div className="text-secondary">{{ data.item.name }}</div>
                                                </div>
                                            </template>
                                            <template #cell(address)="data">
                                                <div className="d-inline-block p-2 rounded-2 bg-light font-monospace">
                                                    {{data.item.address}}
                                                </div>
                                            </template>
                                            <template #cell(balance)="data">
                                                {{ formatBalance(data.item.balance, data.item.decimals)}}
                                            </template>
                                        </b-table> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
      </Container>
    </div>
    </React.Fragment>
  )
}

export default Fund;