import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects"

// Login Redux States
import {LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, TWO_AUTH_SUCCESS, TWO_AUTH_USER} from "./actionTypes"
import {apiError, loginSuccess, logoutUserSuccess, twoAuthSuccess, userLoaded} from "./actions"

//Include Both Helper File with needed methods
import {
  postFakeLogin,
  postFakeTwoAuth,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper"
import {
  getMeInformation,
  postApiLogin,
} from "../../../helpers/fire_api_helper"
import {selectAccessToken} from "./selector"
import {publicRoutes} from "../../../routes"
import {getTokenItemName} from "../../../helpers/env_helper";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const authMethod = 'undefined' === typeof process ? 'api' : process?.env?.REACT_APP_DEFAULTAUTH
    const itemName = getTokenItemName()

    if (authMethod === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem(itemName, JSON.stringify(response))
      yield put(loginSuccess(response, navigate))
    } else if (authMethod === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      // localStorage.setItem(getTokenItemName(), JSON.stringify(response))
      yield put(loginSuccess(response, navigate))
    } else if (authMethod === "api") {
      const response = yield call(postApiLogin, {
        username: user.email,
        password: user.password,
        grant_type: 'password',
      })
      localStorage.setItem(getTokenItemName(), response.token)
      yield put(loginSuccess(response.token, navigate))
    }
  } catch (error) {
    yield put(apiError(error.toString()))
  }
}

function* getMe({ payload: { token, navigate } }) {
  try {
    const body = yield call(getMeInformation, token)

    yield put(userLoaded(body))
    navigate?.("/dashboard")
  } catch (error) {
    if (error.status !== 401) {
      yield put(apiError(error, false, true))
    }
  }
}

function* logoutUser({ payload: { navigate } }) {
  try {
    localStorage.removeItem(getTokenItemName())

    navigate("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* twoAuthUser({ payload: {  username, password, code2FA, navigate } }) {
  try {
    const response = yield call(postApiLogin, {
      username: username,
      password: password,
      '2fa_code': code2FA,
      grant_type: 'password',
    })
    const item = 'undefined' !== typeof process ? getTokenItemName() : 'app_access_token';

    if ('undefined' !== typeof localStorage) {
      localStorage.setItem(item, response.token)
    }

    yield put(twoAuthSuccess(response.token, navigate))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(TWO_AUTH_USER, twoAuthUser)
  yield takeEvery(LOGIN_SUCCESS, getMe)
  yield takeEvery(TWO_AUTH_SUCCESS, getMe)
  yield takeEvery(TWO_AUTH_USER, twoAuthUser)
  yield takeEvery(LOGOUT_USER, logoutUser)

  const accessToken = yield select(selectAccessToken)

  if (accessToken) {
    yield put(loginSuccess(accessToken, path => {
      publicRoutes.forEach(route => {
        if (route.path === window.location.pathname) {
          window.location.href = path
        }
      })
    }))
  }
}

export default authSaga
