import {
  GET_WALLET_FAIL,
  GET_WALLET_SUCCESS,
  GET_CRYPTO_ORDERS_SUCCESS,
  GET_CRYPTO_ORDERS_FAIL,
  GET_FUNDS_SUCCESS,
  GET_FUNDS_FAIL,
  STORE_FUND_FACTORY_ABI,
  GET_FUNDS,
  GET_WHITELISTS_SUCCESS,
  GET_WHITELISTS_FAIL,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_SUCCESS,
  SET_WEB3_DATAS,
  SET_WEB3_SUCCESS,
  SET_WEB3_FAIL,
  DISCONNECT_WALLET,
  TOKEN_LIST_SUCCESS,
} from "./actionTypes"

const ZERO_EX_EXCHANGE_ADDRESS = "0xdef1c0ded9bec7f1a1670819833240f027b25eff";

const CONTRACTS_STATE = {
  fundFactoryAbi: null,
  fundFactoryAddress: null,
  fundLogicAbi: null,
  fundLogicAddress: null,
  usdcAddress: null,
  funds: [],
  protocol: {
    address: "",
    fundLogicAddress: "",
    usdcAddress: "",
    ownerAddress: "",
    nbFund: 0,
    usdcBalance: 0
  },
  whitelists: [],
  currentWhitelist: [],
  zeroExExchnageAddress: ZERO_EX_EXCHANGE_ADDRESS,
  sharedTokensList: [],
  currentTrackedAssets: [],
}

const ACCOUNT_STATE = {
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainName: null,
  providerEthers: null, // this is "provider" for Ethers.js
  isWalletConnected: false,
  isLoadingWallet: false,
  usdcBalance: 0,
  hasMetamask: false
}

const INIT_STATE = {
  wallet: {},
  orders: [],
  tokenList: [],
  isLoading: false,
  ...CONTRACTS_STATE,
  ...ACCOUNT_STATE,
}

const Crypto = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_WEB3_DATAS:
      return {
        ...state,
        isLoadingWallet: true
      }

    case SET_WEB3_SUCCESS:
      return {
        ...state,
        isLoadingWallet: false
      }

    case SET_WEB3_FAIL:
      return {
        ...state,
        isLoadingWallet: false
      }

    case GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload,
      }

    case GET_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_FUNDS:
      return {
        ...state,
        isLoading: true,
      }
      
    case GET_FUNDS_SUCCESS:
      return {
        ...state,
        funds: action.payload,
        isLoading: false,
      }

    case GET_FUNDS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_CRYPTO_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_CRYPTO_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case STORE_FUND_FACTORY_ABI:
      return {
        ...state,
        fundFactoryAddress: action.payload,
      }

    case GET_WHITELISTS_SUCCESS:
      return {
        ...state,
        whitelists: action.payload
      }

    case GET_WHITELISTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CONNECT_WALLET_SUCCESS:
      return {
        ...state,
        activeBalance: action.payload.balance,
        activeAccount: action.payload.activeAccount,
        chainId: action.payload.chainId,
        chainName: action.payload.chainName,
        isWalletConnected: true
      }
    
    case CONNECT_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        activeBalance: 0,
        activeAccount: null,
        isWalletConnected: false
      }
    
    case DISCONNECT_WALLET:
      return {
        ...state,
        activeBalance: 0,
        activeAccount: null,
        isWalletConnected: false
      }

    case TOKEN_LIST_SUCCESS:
      console.log("tokenList", action.payload);
      return {
        ...state,
        tokenList: action.payload
      }
        
    default:
      return state
  }
}

export default Crypto
