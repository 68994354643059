import PropTypes from 'prop-types';
import React, { useState } from "react";

// Components
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConnectWalletModal from 'components/web3/ConnectWalletModal';
import CreateFundButton from 'components/web3/CreateFundButton';
import ConnectWalletButton from 'components/web3/ConnectWalletButton';

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/hesiode_logo.svg";
import logoLightSvg from "../../assets/images/hesiode_logo.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
} from "../../store/actions";
import { getActiveBalanceEth, isGoodNetworkSelector } from "store/crypto/selector";

const Header = props => {
  const [search, setsearch] = useState(false);

  const isGoodNetwork = useSelector(isGoodNetworkSelector)
  const activeBalanceEth = useSelector(getActiveBalanceEth)
  const { isWalletConnected, activeAccount, isLoadingWallet } = useSelector((state) => ({
    isWalletConnected: state.crypto.isWalletConnected,
    activeAccount: state.crypto.activeAccount,
    isLoadingWallet: state.crypto.isLoadingWallet
  }))

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>


            <div className="d-flex gap-2">
              {/* Create fund button */}
              {
                isGoodNetwork && isWalletConnected && (
                  <div className="d-inline-block">
                    <div className="header-item d-flex flex-row align-items-center">
                      <CreateFundButton />
                    </div>
                  </div>
                )
              }
              
              {/* Connection button */}
              <div className="d-inline-block">
                <div className="header-item d-flex flex-row align-items-center gap-2">
                  <ConnectWalletModal />
                  <span className="d-none d-sm-block p-2 mr-2 border rounded-2">Polygon</span>
                </div>
              </div>
            </div>

            <LanguageDropdown />

            <NotificationDropdown />
            <ProfileMenu />

            <div
               onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    showRightSidebar,
  } = state.Layout;
  return { showRightSidebar };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
})(withTranslation()(Header));
