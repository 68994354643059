/* WEB3 */
export const SET_WEB3_DATAS = "SET_WEB3_DATAS"
export const SET_WEB3_SUCCESS = "SET_WEB3_SUCCESS"
export const SET_WEB3_FAIL = "SET_WEB3_FAIL"

/* CONTRACTS */
export const INIT_CONTRACTS = "INIT_CONTRACTS"

/* Wallet */
export const GET_WALLET = "GET_WALLET"
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS"
export const GET_WALLET_FAIL = "GET_WALLET_FAIL"
export const CONNECT_WALLET = "CONNECT_WALLET"
export const CONNECT_WALLET_SUCCESS = "CONNECT_WALLET_SUCCESS"
export const CONNECT_WALLET_FAIL = "CONNECT_WALLET_FAIL"
export const DISCONNECT_WALLET = "DISCONNECT_WALLET"

/* ORDERS */
export const GET_CRYPTO_ORDERS = "GET_CRYPTO_ORDERS"
export const GET_CRYPTO_ORDERS_SUCCESS = "GET_CRYPTO_ORDERS_SUCCESS"
export const GET_CRYPTO_ORDERS_FAIL = "GET_CRYPTO_ORDERS_FAIL"

/* FUNDS */
export const GET_FUNDS = "GET_FUNDS"
export const GET_FUNDS_SUCCESS = "GET_FUNDS_SUCCESS"
export const GET_FUNDS_FAIL = "GET_FUNDS_FAIL"
export const STORE_FUND_FACTORY_ABI = "STORE_FUND_FACTORY_ABI"
export const GET_WHITELISTS = "GET_WHITELISTS"
export const GET_WHITELISTS_SUCCESS = "GET_WHITELISTS_SUCCESS"
export const GET_WHITELISTS_FAIL = "GET_WHITELISTS_FAIL"

// Api Token
export const TOKEN_LIST = "TOKEN_LIST"
export const TOKEN_LIST_SUCCESS = "TOKEN_LIST_SUCCESS"
export const TOKEN_LIST_FAIL = "TOKEN_LIST_FAIL"