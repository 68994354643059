import React, { useEffect } from "react"

import { logoutUser } from "../../store/actions"

import { useParams, useNavigate } from "react-router-dom"

//redux
import { useDispatch } from "react-redux"

const Logout = () => {
  const dispatch = useDispatch()
  const params = useParams()
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutUser(navigate))
  }, [dispatch])

  return <></>
}

export default Logout
