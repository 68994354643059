import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMER_PROFILE,
    GET_CUSTOMER_PROFILE_FAIL,
    GET_CUSTOMER_PROFILE_SUCCESS,
    ADD_NEW_INVESTOR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL, RESET_ADD_NEW_INVESTOR,
} from "./actionTypes"


export const getCustomers = searchValue => ({
  type: GET_CUSTOMERS,
  search: searchValue,
})
  
export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const getCustomerProfileAction = customerId => ({
  type: GET_CUSTOMER_PROFILE,
  customerId,
})

export const getCustomerProfileSuccess = customer => ({
  type: GET_CUSTOMER_PROFILE_SUCCESS,
  payload: customer,
})

export const getCustomerProfileFail = error => ({
  type: GET_CUSTOMER_PROFILE_FAIL,
  payload: error,
})

export const resetAddNewCustomer = () => ({
  type: RESET_ADD_NEW_INVESTOR,
  payload: { },
})

export const addNewCustomer = customer => ({
  type: ADD_NEW_INVESTOR,
  payload: customer,
})

export const addNewInvestor = investor => ({
  type: ADD_NEW_INVESTOR,
  payload: investor
})

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
})

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
})

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
})

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer,
})

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
})
