// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  TOGGLE_OK_MODAL,
  TOGGLE_NEW_FUND_MODAL,
  TOGGLE_NEW_WHITELIST_MODAL,
  TOGGLE_UPDATE_WHITELIST_MODAL,
  TOGGLE_ADD_TOKEN_MODAL,
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";
import {
  CREATE_NEW_FUND_SUCCESS,
  CREATE_NEW_WHITELIST_SUCCESS,
} from "store/fund/actionTypes";

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  modalOk: {
    isOpen: false,
    text: "",
    title: ""
  },
  modalNewWallet: {
    isOpen: false
  },
  modalNewWhitelist: {
    isOpen: false
  },
  modalUpdateWhitelist: {
    isOpen: false
  },
  modalAddToken: {
    isOpen: false
  },
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }
    
    case TOGGLE_OK_MODAL:
      return {
        ...state,
        modalOk: {
          ...state.modalOk,
          isOpen: action.payload.isOpen,
          text: action.payload.text,
          title: action.payload.title
        }
      }
    
    case TOGGLE_NEW_FUND_MODAL:
      console.log("TOGGLE_NEW_FUND_MODAL", action);
      return {
        ...state,
        modalNewWallet: {
          ...state.modalNewWallet,
          isOpen: action.payload
        }
      }
    
    case CREATE_NEW_FUND_SUCCESS:
      return {
        ...state,
        modalNewWallet: {
          ...state.modalNewWallet,
          isOpen: false
        },
        modalOk: {
          ...state.modalOk,
          isOpen: true,
          text: action.payload.text,
          title: action.payload.title
        }
      }

    case CREATE_NEW_WHITELIST_SUCCESS:
      return {
        ...state,
        modalNewWhitelist: {
          ...state.modalNewWhitelist,
          isOpen: false
        },
        modalOk: {
          ...state.modalOk,
          isOpen: true,
          text: action.payload.text,
          title: action.payload.title
        }
      }
      
    case TOGGLE_NEW_WHITELIST_MODAL:
      console.log("TOGGLE_NEW_WHITELIST_MODAL", action);
      return {
        ...state,
        modalNewWhitelist: {
          ...state.modalNewWhitelist,
          isOpen: action.payload
        }
      }
    
    case TOGGLE_UPDATE_WHITELIST_MODAL:
      console.log("TOGGLE_UPDATE_WHITELIST_MODAL", action);
      return {
        ...state,
        modalUpdateWhitelist: {
          ...state.modalUpdateWhitelist,
          isOpen: action.payload
        }
      }

      case TOGGLE_ADD_TOKEN_MODAL:
        console.log("TOGGLE_ADD_TOKEN_MODAL", action);
        return {
          ...state,
          modalAddToken: {
            ...state.modalAddToken,
            isOpen: action.payload
          }
        }

    default:
      return state
  }
}

export default Layout
