import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {useResource} from "../../components/Crud/hooks";
import {Card, CardBody, CardTitle} from "reactstrap";
import Filters from "../../cruds/fund/Filters";
import FundTable from "../../cruds/fund/FundTable";

function Favorites(props) {
    const favoriteFundsResource = useResource('/api/v1/funds/favorites', '/fond', 'dashboardFavoriteFunds')

    return (
        <div>
            <div className={"mb-2 d-flex justify-content-between align-items-center"}>
            </div>
            <Card>
                <CardTitle className={"pt-3 px-4 pb-0"}>
                    {props.t('My favorite funds')}
                </CardTitle>
                <CardBody>
                    <Filters resource={favoriteFundsResource} />
                    <FundTable
                        resource={favoriteFundsResource}
                        missingFundMessage={props.t('You have no favorite fund')}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

Favorites.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Favorites)
