module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  REACT_APP_ACCESS_TOKEN_ITEM_NAME: "prometheus_fire_access_token",
  REACT_APP_DEFAULTAUTH: "api"
}
