import React from "react";
import {
    Button,
} from "reactstrap"

//i18n
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";




import {
    toggleNewFundModal
} from "store/actions";



const CreateFundButton = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const { isNewFundModalOpen } = useSelector((state) => ({
        isNewFundModalOpen: state.Layout.modalNewWallet.isOpen,
    }))

    const setIsOpen = (isOpen) => {
        (isOpen !== isNewFundModalOpen ) && dispatch(toggleNewFundModal(!isNewFundModalOpen))
    }

    return (
        <div>
            <Button color="primary" onClick={() => setIsOpen(true)} className="p-sm-2 d-none d-sm-block">{t("Creat product")}</Button>
        </div>
    )
}

export default CreateFundButton