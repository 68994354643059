import { ethers } from 'ethers'
import FundFactory from "../../contracts/FundFactory.json";
import FundLogic from "../../contracts/FundLogic.json";
import ERC20 from "../../contracts/USDC.json";
import addresses from "../../contracts/addresses.json";

import { call, put, takeEvery, select, take } from "redux-saga/effects"

// Crypto Redux States
import {
  CREATE_NEW_FUND,
  CREATE_NEW_WHITELIST,
  FETCH_INVESTORS,
  FETCH_MANAGERS,
  GET_CURRENT_FUND,
  GET_SHARE_IN_FUND,
} from "./actionTypes"
import {
  getFundsSuccess,
  getFundsFail,
  getWhitelistsFail,
  getWhitelistsSuccess,
  getCurrentFundFail,
  getCurrentFundSuccess,
  fetchShareInFundSuccess,
  fetchShareInFundFail,
  fetchManagersSuccess,
  fetchManagersFail,
  fetchInvestorsSuccess,
  fetchInvestorsFail,
  createNewFundFail,
  createNewFundSuccess,
  createNewWhitelistFail,
  createNewWhitelistSuccess,
} from "./actions"
import { toggleOkModal } from 'store/actions';

const MANAGER_ROLE = "0xaf290d8680820aad922855f39b306097b20e28774d6c1ad35a20325630c3a02c";

const FACTORY_CONTRACT_NAME = "FundFactory"
const FUND_CONTRACT_NAME = "FundLogic"

//////////////
//    UTILS
//////////////

const getShareInFund = async (fundAddress) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)
  const { provider } = web3Provider
  const activeAccount = provider.selectedAddress

  const fund = new ethers.Contract(fundAddress, FundLogic.abi, web3Provider);

  if (activeAccount) {
    return await fund.balanceOf(activeAccount)
  }
  return 0
}

const getManagersInFund = async (fundAddress) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)

  const fund = new ethers.Contract(fundAddress, FundLogic.abi, web3Provider);

  const managerCount = await fund.getRoleMemberCount(MANAGER_ROLE);

  const managers = [];
  for (let i = 0; i < managerCount; ++i) {
    const manager = await fund.getRoleMember(MANAGER_ROLE, i) 
    managers.push(manager.toLowerCase());
  }
  return managers
}

const getInvestrorsInFund = async (fundAddress) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)

  const fund = new ethers.Contract(fundAddress, FundLogic.abi, web3Provider);

  const investors = await fund.getInvestorWhitelist();

  return investors.map(investor => investor.toLowerCase())
}

const createFundOnChain = async (fundFactoryAddress, { fundPaymentFees, fundManagmentFees, tokenName, tokenSymbol, idWhitelist, useWhitelist }) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)
  
  const signer = web3Provider.getSigner();

  const fundFactory = new ethers.Contract(fundFactoryAddress, FundFactory.abi, signer);

  const managmentFeesConverted = isNaN(fundManagmentFees) ? 0 : parseInt(fundManagmentFees * 100)
  const paymentFeesConverted = isNaN(fundPaymentFees) ? 0 : parseInt(fundPaymentFees * 100)
  if (!useWhitelist) {
    idWhitelist = 0
  }
  const newFund = await fundFactory.createFund(
    paymentFeesConverted,
    managmentFeesConverted,
    tokenName,
    tokenSymbol,
    idWhitelist,
    useWhitelist,
    { gasLimit: 1000000 }
  )
  return newFund
}

const createWhitelistOnChain = async (fundFactoryAddress, name) => {
  const { ethereum } = window
  if (!ethereum) {
    return
  }
  const web3Provider = new ethers.providers.Web3Provider(ethereum)
  
  const signer = web3Provider.getSigner();

  const fundFactory = new ethers.Contract(fundFactoryAddress, FundFactory.abi, signer);

  const newWhitelist = await fundFactory.createWhitelist(name)
  return newWhitelist
}

//////////////
//    SAGA
//////////////
function* fetchCurrentFund({fundAddress}) {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }

    const web3Provider = new ethers.providers.Web3Provider(ethereum)
  
    const fund = new ethers.Contract(fundAddress, FundLogic.abi, web3Provider)

    const fundInformationArray = yield call(fund.getFundInformations)

    const [
      name,
      symbol,
      totalSupply,
      owner,
      feeBpsForFund,
      feeDepositForFund,
      feeBpsForProtocol,
      feeDepositForProtocol,
      denominationAssetBalance,
      trackedAssets,
      useTokensWhitelist,
      tokensWhitelistId,
    ] = fundInformationArray
    const fundInformation = {
      fundAddress,
      name,
      symbol,
      totalSupply,
      owner,
      feeBpsForFund,
      feeDepositForFund,
      feeBpsForProtocol,
      feeDepositForProtocol,
      denominationAssetBalance,
      trackedAssets,
      useTokensWhitelist,
      tokensWhitelistId,
    }

    yield put(getCurrentFundSuccess(fundInformation))
  } catch (error) {
    yield put(getCurrentFundFail(error))
  }
}

function* fetchShareInFund({ fundAddress }) {
  try {
    const shareInFund = yield call(getShareInFund, fundAddress)
    
    yield put(fetchShareInFundSuccess(shareInFund))
  } catch (error) {
    yield put(fetchShareInFundFail(error))
  }
}

function* fetchManagers({ fundAddress }) {
  try {
    const managers = yield call(getManagersInFund, fundAddress)

    yield put(fetchManagersSuccess(managers))
  } catch (error) {
    yield put(fetchManagersFail(error))
  }
}

function* fetchInvestors({ fundAddress }) {
  try {
    const investors = yield call(getInvestrorsInFund, fundAddress)

    yield put(fetchInvestorsSuccess(investors))
  } catch (error) {
    yield put(fetchInvestorsFail(error))
  }
}

function* createNewFund({ fund }) {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }

    const chainId = parseInt(ethereum.chainId)
    const fundFactoryAddress = addresses[FACTORY_CONTRACT_NAME][chainId];

    const newFund = yield call(createFundOnChain, fundFactoryAddress, fund)

    yield put(createNewFundSuccess({
      title: "Le fond a bien été créé."
    }))
  } catch (error) {
    yield put(createNewFundFail(error))
  }
}

function* createNewWhitelist({ name }) {
  try {
    const { ethereum } = window
    if (!ethereum) {
      return
    }

    const chainId = parseInt(ethereum.chainId)
    const fundFactoryAddress = addresses[FACTORY_CONTRACT_NAME][chainId];

    const newWhitelist = yield call(createWhitelistOnChain, fundFactoryAddress, name)

    yield put(createNewWhitelistSuccess({
      title: "La whitelist a bien été créée."
    }))
  } catch (error) {
    yield put(createNewWhitelistFail(error))
  }
}

function* cryptoSaga() {
  yield takeEvery(GET_CURRENT_FUND, fetchCurrentFund)
  yield takeEvery(GET_SHARE_IN_FUND, fetchShareInFund)
  yield takeEvery(FETCH_MANAGERS, fetchManagers)
  yield takeEvery(FETCH_INVESTORS, fetchInvestors)
  yield takeEvery(CREATE_NEW_FUND, createNewFund)
  yield takeEvery(CREATE_NEW_WHITELIST, createNewWhitelist)
}

export default cryptoSaga
