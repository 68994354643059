import {resourceShape} from "../../components/Crud/shapes";
import ResourceDataTable from "../../components/Crud/ResourceDataTable";
import VLCell from "./VLCell";
import {useSelector} from "react-redux";
import {Alert} from "reactstrap";
import PropTypes from "prop-types";

const columns = [
    {title: 'Name', data: 'name'},
    {title: 'Symbol', data: 'symbol'},
    {title: 'Denomination Token', data: 'denomination_token'},
    {title: 'Address', data: 'address'},
    {title: 'Current Liquidative Value', data: row => `${row.values.last}$`},
    {title: '1h', data: row => <VLCell lastValue={row.values.last} value={row.values.one_hour} />},
    {title: '24h', data: row => <VLCell lastValue={row.values.last} value={row.values.one_day} />},
    {title: '7d', data: row => <VLCell lastValue={row.values.last} value={row.values.one_week} />},
    {title: 'AUM', data: row => `${row.total_supply}$`},
    {title: 'Asset balance', data: 'asset_balance'},
    {title: 'Management Fees', data: row => `${row.fee_bps_for_fund}%`},
    {title: 'Entry Fees', data: row => `${row.fee_deposit_for_fund}%`},
]

function FundTable(props) {
    const page = useSelector(props.resource.selectPage())
    return (
        <ResourceDataTable
            resource={props.resource}
            rowEdit={false}
            rowDelete={false}
            columns={columns}
            initFilters={props.initFilters}
        >
            {!props.missingFundMessage || page?.items?.length !== 0 ? null : (
                <tr>
                    <td colSpan={columns.length + 1}>
                        <Alert color={'info'} isOpen={true} className={"text-center"}>
                            <i className={"bx bx-info-circle px-2"} />
                            {props.missingFundMessage}
                        </Alert>
                    </td>
                </tr>
            )}
        </ResourceDataTable>
    )
}

FundTable.propTypes = {
    resource: resourceShape.isRequired,
    missingFundMessage: PropTypes.string,
    initFilters: PropTypes.object,
}

export default FundTable
