import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import img from "assets/images/web3-pending.svg"
import { useDispatch, useSelector } from "react-redux";
import { toggleOkModal } from "store/actions";

const TransactionOkModal = () => {
    const dispatch = useDispatch()

    const [title, setTitle] = useState("La transaction a bien été envoyée.");
    const [text, setTexte] = useState("Vous devez patienter le temps que votre transaction soit prise en compte.");
    const { isOkModalOpen, titleFromState, textFromState } = useSelector(state => ({
        isOkModalOpen: state.Layout.modalOk.isOpen,
        titleFromState: state.Layout.modalOk.title,
        textFromState: state.Layout.modalOk.text
    }));

    const setIsOpen = (isOpen) => {
        (isOpen !== isOkModalOpen ) && dispatch(toggleOkModal({ isOpen: !isOkModalOpen }))
    }

    useEffect(() => {
        titleFromState && setTitle(titleFromState)
    }, [titleFromState])

    useEffect(() => {
        textFromState && setTexte(textFromState)
    }, [textFromState])

    return (
        <Modal centered={true} isOpen={isOkModalOpen} toggle={setIsOpen}>
            <ModalHeader>
                Transaction envoyée
            </ModalHeader>
            <ModalBody>
                <div className="text-center">
                    <img src={img} className="img-fluid w-50" />
                    <h4>{ title }</h4>
                    <p>{ text }</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => setIsOpen(false)}>Valider</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TransactionOkModal