import React from 'react'

// import './index.css'

import {
  Column,
  Table as ReactTable,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  OnChangeFn,
  flexRender,
} from '@tanstack/react-table'
import BootstrapTable from 'react-bootstrap/Table';
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
} from "reactstrap"

const Table = ({ data, columns }) => {
  const table = useReactTable({
    data,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  })

  if (data.length === 0) {
    return (
      <div className='p-4'>Il n'y a pas de données à afficher</div>
    )
  }
  return (
    <div className="p-2">
      <div>
        <label className='d-inline-flex align-items-center'>
          Afficher
          <Input
            type="select"
            className="mx-2"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
          entrées
        </label>
      </div>
      <div className='table-responsive'>
        <div className='table-responsive-sm'>
          <BootstrapTable className="table b-table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} id={header.id} colSpan={header.colSpan}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}  
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BootstrapTable>
        </div>
      </div>
      <Row>
        <Col>
          <div className="float-end pagination-rounded">
            <Pagination>
              <PaginationItem
                disabled={!table.getCanPreviousPage()}
              >
                <PaginationLink
                  first
                  href="#"
                  onClick={() => table.setPageIndex(0)}
                />
              </PaginationItem>
              <PaginationItem
                disabled={!table.getCanPreviousPage()}
              >
                <PaginationLink
                  href="#"
                  previous
                  onClick={() => table.previousPage()}
                />
              </PaginationItem>
              <PaginationItem
                active={1 === table.getState().pagination.pageIndex + 1}
              >
                <PaginationLink
                  href="#"
                  onClick={() => table.setPageIndex(1)}
                >
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                disabled={!table.getCanNextPage()}
              >
                <PaginationLink
                  href="#"
                  next
                  onClick={() => table.nextPage()}
                />
              </PaginationItem>
              <PaginationItem
                disabled={!table.getCanNextPage()}
              >
                <PaginationLink
                  href="#"
                  last
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                />
              </PaginationItem>
            </Pagination>
            <div className="flex items-center gap-1">
              <div>
                <span className='me-2'>Page</span>
                <strong>
                  {table.getState().pagination.pageIndex + 1}/
                  {table.getPageCount()}
                </strong>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const Filter = ({ column, table }) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue)?.[0] ?? ''}
        onChange={e =>
          column.setFilterValue((old) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue)?.[1] ?? ''}
        onChange={e =>
          column.setFilterValue((old) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? '')}
      onChange={e => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-auto"
    />
  )
}

export default Table