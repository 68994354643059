export function notEmpty(value) {
    return value !== '' && value !== null && value !== undefined && (!(value instanceof Array) || value.length > 0) && (typeof value !== 'object' || Object.keys(value).length > 0);
}

export function filterObject(obj, callback) {
    callback = callback || notEmpty

    return Object.keys(obj)
        .filter(key => callback(obj[key]))
        .reduce((acc, key) => {
            acc[key] = obj[key]
            return acc
        }, {})
}

export function isValidUuid(str) {
    return Boolean(str?.match?.(new RegExp('^[a-fA-F\\d]{8}-[a-fA-F\\d]{4}-[a-fA-F\\d]{4}-[a-fA-F\\d]{4}-[a-fA-F\\d]{12}$')) ?? false)
}
