import PropTypes from "prop-types";

export const resourceShape = PropTypes.shape({
    addresses: PropTypes.shape({
        search: PropTypes.string,
        all: PropTypes.string,
        one: PropTypes.func,
    }),
    name: PropTypes.string,
    basePath: PropTypes.string,
    resetCurrent: PropTypes.func,
    selectCurrent: PropTypes.func,
    selectCurrentLoading: PropTypes.func,
    selectCurrentError: PropTypes.func,
    selectPage: PropTypes.func,
    selectPageFilters: PropTypes.func,
    selectPageInited: PropTypes.func,
    selectPageLoading: PropTypes.func,
    selectPageError: PropTypes.func,
    loadItem: PropTypes.func,
    selectItem: PropTypes.func,
    selectItemLoading: PropTypes.func,
    selectItemError: PropTypes.func,
    selectCreatedItem: PropTypes.func,
    selectCreateItemLoading: PropTypes.func,
    selectCreateItemError: PropTypes.func,
    setCurrent: PropTypes.func,
    resetCreate: PropTypes.func,
    create: PropTypes.func,
    resetUpdate: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    cancelDelete: PropTypes.func,
    loadPage: PropTypes.func,
    initPage: PropTypes.func,
    changeFilter: PropTypes.func,
})
