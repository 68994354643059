import { ethers } from 'ethers'
const { useSelector } = require("react-redux");
import { createSelector } from 'reselect'

const chainIds = {
    POLYGON: "0x89",
    MUMBAY: "0x13881",
    LOCALHOST: "0x7a69"
}

export const isGoodNetworkSelector = createSelector(
    (state) => state.crypto.chainId,
    (chainId) => {
        if (chainId === chainIds.MUMBAY || chainId === chainIds.POLYGON || ('undefined' !== typeof process && process?.env?.NODE_ENV !== "production" && chainId == chainIds.LOCALHOST)) {
            return true
        }
        return false
    }
)

export const getActiveBalanceEth = createSelector(
    (state) => state.crypto.activeBalance,
    (activeBalance) => {
        return ethers.utils.formatEther(activeBalance);
    }
)
