import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Label,
    Input,
} from "reactstrap";

//i18n
import { useTranslation } from "react-i18next"
import {useResource} from "../Crud/hooks";
import {useSelector} from "react-redux";
import ButtonSpinner from "./ButtonSpinner";
import {filterObject} from "../../helpers/object_utils";

const UpdatePatrimonyModal = props => {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const resource = useResource(
        `/api/v1/users/${props.customer}/capital`,
        '',
        'updatePatrimonyModal',
        {one: ''},
    )
    const [value, setValue] = useState({})
    const item = useSelector(resource.selectItem())
    const loading = useSelector(resource.selectItemLoading())
    const updateLoading = useSelector(resource.selectUpdateItemLoading())

    useEffect(() => {
        setValue({})

        if (props.customer) {
            resource.loadItem()
        } else {
            resource.resetItem()
        }
    }, [props.customer])

    useEffect(() => {
        if (item) {
            const newValue = {
                primaryResidence: item?.primary_residence,
                secondaryResidence: item?.secondary_residence,
                rentingInvestment: item?.renting_investment,
                lifeInsurance: item?.life_insurance,
                liquidity: item?.liquidity,
                goldAndArt: item?.gold_and_art,
                professionalActives: item?.professional_actives,
                activeLoanOwedCapital: item?.active_loan_owed_capital,
            }
            props.onLoad?.(newValue)
            setValue(newValue)
        }
    }, [item])

    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
        removeBodyCss();
    }

    function handleChange(event) {
        setValue(current => ({...current, [event.target.name]: event.target.value}))
    }

    return (
        <React.Fragment>
            <Button
                color="primary"
                onClick={() => {
                    toggleModal();
                }}
                data-toggle="modal"
                data-target="#myModal"
                outline={props.minimal}
            >
                <i className={"fas fa-pen" + (props.minimal ? "" : "me-2")}/>
                { props.minimal ? "" : <span>{t("Edit")}</span> }
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggleModal();
                }}
                centered
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t("Customer.updatePatrimony")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-name">{t("Customer.mainHome")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-name"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"primaryResidence"}
                                        value={value?.primaryResidence || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname">{t("Customer.secondHome")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-firstname"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"secondaryResidence"}
                                        value={value?.secondaryResidence || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.rentalInvestment")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"rentingInvestment"}
                                        value={value?.rentingInvestment || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.securities")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"lifeInsurance"}
                                        value={value?.lifeInsurance || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.cash")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"liquidity"}
                                        value={value?.liquidity || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.gold")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"goldAndArt"}
                                        value={value?.goldAndArt || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.professionalAssets")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"professionalActives"}
                                        value={value?.professionalActives || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="formrow-address">{t("Customer.capitalRemaining")}</Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="formrow-address"
                                        placeholder={t("Wallet.enterAmount")}
                                        name={"activeLoanOwedCapital"}
                                        value={value?.activeLoanOwedCapital || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal();
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        {t("Close")}
                    </button>
                    <button
                        type="button"
                        disabled={loading || !item}
                        onClick={() => {
                            props.onSubmit?.(value)
                            resource.update(item.id, filterObject({
                                primary_residence: value.primaryResidence,
                                secondary_residence: value.secondaryResidence,
                                renting_investment: value.rentingInvestment,
                                life_insurance: value.lifeInsurance,
                                liquidity: value.liquidity,
                                gold_and_art: value.goldAndArt,
                                professional_actives: value.professionalActives,
                                active_loan_owed_capital: value.activeLoanOwedCapital,
                            }))
                            setIsModalOpen(!isModalOpen)
                        }}
                        className="btn btn-primary w-md">
                        {updateLoading ? <ButtonSpinner /> : t("Update")}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

UpdatePatrimonyModal.propTypes = {
    customer: PropTypes.string.isRequired,
    minimal: PropTypes.any,
    onLoad: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default UpdatePatrimonyModal
