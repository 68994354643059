import React, { useEffect, useState } from "react";
import {
    Button,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux";

//i18n
import { useTranslation } from "react-i18next"

import {
    toggleAddTokenModal
} from "store/actions";

const AddTokenButton = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isAddTokenModalOpen } = useSelector((state) => ({
        isAddTokenModalOpen: state.Layout.modalAddToken.isOpen,
    }))

    const setIsOpen = (isOpen) => {
        (isOpen !== isAddTokenModalOpen ) && dispatch(toggleAddTokenModal(!isAddTokenModalOpen))
    }
    return (
        <div>
            <Button size="sm" color="primary" onClick={() => setIsOpen(true)}>{t("Add a token")}</Button>
        </div>
    )
}

export default AddTokenButton