import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from "react-redux"
import MetaTags from 'react-meta-tags';
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table
} from "reactstrap"
import { useParams } from "react-router-dom"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"

//Actions
import { getCustomerProfileAction } from "store/actions"

//i18n
import { useTranslation, withTranslation } from "react-i18next"
import UpdatePatrimonyModal from "components/Common/UpdatePatrimonyModal";
import UpdateCustomerPersonnalInformationModal from "components/Common/UpdateCustomerPersonnalInformationModal";
import UpdateEarningModal from "components/Common/UpdateEarningModal";
import { selectCurrentCustomer } from "../../store/customer/selectors";
import CustomerBlockchainAddressModal from "pages/Customer/CustomerBlockchainAddressModal";


const CustomerProfil = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer)
  const { id: customerId } = useParams()

  useEffect(() => {
    const timeout = setTimeout(() => dispatch(getCustomerProfileAction(customerId)))

    return () => clearTimeout(timeout)
  }, [customerId])

   console.log(currentCustomer)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('Profile')} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          <h4>{t('Profile')}</h4>
          <Row>
            <Col md="6" lg="12" xl="6" xxl="5">
              <Card>
                <CardBody>
                  <div className="mb-4 d-flex justify-content-between align-items-center">
                    <CardTitle>{t("Customer.personnalInformation")}</CardTitle>
                    <UpdateCustomerPersonnalInformationModal />
                  </div>
                  <div className="text-center">
                    <img
                      src={avatar1}
                      alt=""
                      className="mx-auto avatar-md rounded-circle img-thumbnail"
                    />
                    {/* name firstname */}
                    <h5 className="text-center">{currentCustomer.first_name} {currentCustomer.last_name}</h5>
                  </div>
                  <p className="text-muted mb-2">
                    {currentCustomer.comment}
                  </p>
                  <div className="table-responsive mb-3">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{t("Customer.maritalStatus")}:</th>
                          <td>{currentCustomer.maritalStatus}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Customer.phone")}:</th>
                          <td>{currentCustomer.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Email")}:</th>
                          <td>{currentCustomer.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Customer.address")}:</th>
                          <td>{currentCustomer?.address?.lines?.map((line, index) => (
                            <span key={index}>{line} <br /></span>
                          ))}
                            {currentCustomer?.address?.zip_code}, {currentCustomer?.address?.city}
                            {Boolean(currentCustomer?.address?.country || currentCustomer?.address?.state) && (
                              <>
                                <br />
                                {currentCustomer?.address?.state ? currentCustomer?.address?.state + ',' : ''}
                                {currentCustomer?.address?.country}
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" lg="12" xl="6" xxl="5">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <span className="text-muted fw-medium">
                              {t("Customer.patrimony")}
                            </span>
                          </div>
                          <h4 className="mb-0">
                            {currentCustomer.patrimony}</h4>
                        </div>
                        <UpdatePatrimonyModal customer={currentCustomer.id} minimal className="mb-2" />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <span className="text-muted fw-medium">
                              {t("Customer.earning")}
                            </span>
                          </div>
                          <h4 className="mb-0">{currentCustomer.earning}</h4>
                        </div>
                        <UpdateEarningModal
                          minimal
                          customer={currentCustomer.id}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="6" lg="6" xl="6" xxl="5">
              <Card>
                <CardBody>
                  <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div className="h4 card-title">{t('Wallets')}</div>
                    <div>
                    <CustomerBlockchainAddressModal 
                    ButtonName = {t('Wallet.addNewWallet')} 
                    ModalTitleLabel ={t('Wallet.name')} 
                    ModalAddressLabel={t('Wallet.address')}
                    ModalMessageNameField ={t('WalletAddress.pleaseEnterName')}
                    ModalMessageAdressField = {t('WalletAddress.pleaseEnterAddress')}
                    UserPayementEntity = {currentCustomer.first_name}//Faire le changement pour quand on aura accès au payementEntity
                    tag = "wallet_crypto"
                    blockchain = "70340da2-d9c0-44f2-ae57-7294e16dbc68" //Il faudra voir comment on peut récupérer l'ID de la blockchain en BDD

                    />
                    </div>
                  </div>
                 

                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>

  )
}





export default withTranslation()(CustomerProfil)
