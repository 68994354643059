import {
  CREATE_NEW_FUND,
  CREATE_NEW_FUND_FAIL,
  CREATE_NEW_FUND_SUCCESS,
  CREATE_NEW_WHITELIST,
  CREATE_NEW_WHITELIST_FAIL,
  CREATE_NEW_WHITELIST_SUCCESS,
  FETCH_INVESTORS,
  FETCH_INVESTORS_FAIL,
  FETCH_INVESTORS_SUCCESS,
  FETCH_MANAGERS,
  FETCH_MANAGERS_FAIL,
  FETCH_MANAGERS_SUCCESS,
  GET_CURRENT_FUND,
  GET_CURRENT_FUND_FAIL,
  GET_CURRENT_FUND_SUCCESS,
  GET_SHARE_IN_FUND,
  GET_SHARE_IN_FUND_FAIL,
  GET_SHARE_IN_FUND_SUCCESS,
} from "./actionTypes"

export const getCurrentFund = (fundAddress) => ({
  type: GET_CURRENT_FUND,
  fundAddress
})

export const getCurrentFundSuccess = fund => ({
  type: GET_CURRENT_FUND_SUCCESS,
  payload: fund,
})

export const getCurrentFundFail = error => ({
  type: GET_CURRENT_FUND_FAIL,
  payload: error,
})

export const fetchShareInFund = fundAddress => ({
  type: GET_SHARE_IN_FUND,
  fundAddress
})

export const fetchShareInFundSuccess = share => ({
  type: GET_SHARE_IN_FUND_SUCCESS,
  payload: share
})

export const fetchShareInFundFail = error => ({
  type: GET_SHARE_IN_FUND_FAIL,
  payload: error,
})

export const fetchManagers = fundAddress => ({
  type: FETCH_MANAGERS,
  fundAddress
})

export const fetchManagersSuccess = managers => ({
  type: FETCH_MANAGERS_SUCCESS,
  payload: managers
})

export const fetchManagersFail = error => ({
  type: FETCH_MANAGERS_FAIL,
  payload: error,
})

export const fetchInvestors = fundAddress => ({
  type: FETCH_INVESTORS,
  fundAddress
})

export const fetchInvestorsSuccess = investors => ({
  type: FETCH_INVESTORS_SUCCESS,
  payload: investors
})

export const fetchInvestorsFail = error => ({
  type: FETCH_INVESTORS_FAIL,
  payload: error,
})

export const createNewFund = (fund) => ({
  type: CREATE_NEW_FUND,
  fund
})

export const createNewFundSuccess = ({ text = "", title = "" }) => ({
  type: CREATE_NEW_FUND_SUCCESS,
  payload: { text, title }
})

export const createNewFundFail = error => ({
  type: CREATE_NEW_FUND_FAIL,
  payload: error,
})

export const createNewWhitelist = name => ({
  type: CREATE_NEW_WHITELIST,
  name
})

export const createNewWhitelistSuccess = ({ text = "", title = "" }) => ({
  type: CREATE_NEW_WHITELIST_SUCCESS,
  payload: { text, title }
})

export const createNewWhitelistFail = error => ({
  type: CREATE_NEW_WHITELIST_FAIL,
  payload: error
})