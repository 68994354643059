import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../helpers/api_helper";
import {
    selectCrudCreatedItem, selectCrudCreateItemError, selectCrudCreateItemLoading,
    selectCrudItem, selectCrudItemError,
    selectCrudItemLoading,
    selectCrudPage, selectCrudPageError, selectCrudPageFilters, selectCrudPageInited,
    selectCrudPageLoading, selectCrudUpdatedItem, selectCrudUpdateItemError, selectCrudUpdateItemLoading
} from "../../store/crud/selectors";
import {
    cancelDeleteItem, changePageFilter,
    changePageFilters,
    createItem, deleteItem,
    initPage,
    loadItem,
    loadPage, resetCreateItem,
    resetItem, resetUpdateItem,
    updateItem
} from "../../store/crud/actions";
import {isValidUuid} from "../../helpers/object_utils";
import {useNavigate} from "react-router-dom";

export function initResourcePage(resource, filters) {
    const inited = useSelector(selectCrudPageInited(resource.name))
    const dispatch = useDispatch()

    useEffect(() => {
        if (!inited) {
            const timeout = setTimeout(() => {
                dispatch(changePageFilters(resource.name, filters))
                resource.initPage(0, filters)
            })

            return () => clearTimeout(timeout)
        }
    }, [inited])

    return inited
}

export function initResourceItem(resource, id) {
    const navigate = useNavigate()
    const current = useSelector(resource.selectCurrent())

    useEffect(() => {
        if (!isValidUuid(id)) {
            navigate(resource.basePath);
        }

        if (current?.id !== id) {
            const timeout = setTimeout(() => {
                resource.setCurrent(id)
            }, 200)

            return () => clearTimeout(timeout)
        }
    }, [current, id])
}

export function useResource(apiPath, appPath, name, baseAddresses) {
    name = name || apiPath

    const dispatch = useDispatch()
    const oneBaseAddress = typeof baseAddresses?.one === 'function' ?
        baseAddresses.one :
        id => baseAddresses?.one ?? ('/' + id)

    const addresses = {
        search: API_URL  + apiPath + (baseAddresses?.serach || '/search'),
        all: API_URL + apiPath + (baseAddresses?.all || ''),
        one: id => API_URL + apiPath + oneBaseAddress(id),
    }

    function doLoadPage() {
        dispatch(loadPage(name, addresses.search))
    }

    function doInitPage(filters) {
        dispatch(initPage(name))

        if (filters) {
            dispatch(changePageFilters(filters))
        }

        doLoadPage()
    }

    return {
        name,
        basePath: appPath,
        addresses,
        resetCurrent: () => resetItem(name),
        selectCurrent: () => selectCrudItem(name),
        selectCurrentLoading: () => selectCrudItemLoading(name),
        selectCurrentError: selectCrudItemError(name),
        selectPage: () => selectCrudPage(name),
        selectPageFilters: () => selectCrudPageFilters(name),
        selectPageInited: () => selectCrudPageInited(name),
        selectPageLoading: () => selectCrudPageLoading(name),
        selectPageError: () => selectCrudPageError(name),
        resetItem: () => {
            dispatch(resetItem(name))
        },
        loadItem: id => {
            dispatch(loadItem(name, addresses.one(id)))
        },
        selectItem: () => selectCrudItem(name),
        selectItemLoading: () => selectCrudItemLoading(name),
        selectItemError: () => selectCrudItemError(name),
        selectCreatedItem: () => selectCrudCreatedItem(name),
        selectCreateItemLoading: () => selectCrudCreateItemLoading(name),
        selectCreateItemError: () => selectCrudCreateItemError(name),
        selectUpdatedItem: () => selectCrudUpdatedItem,
        selectUpdateItemLoading: () => selectCrudUpdateItemLoading,
        selectUpdateItemError: () => selectCrudUpdateItemError,
        setCurrent: id => {
            dispatch(loadItem(name, addresses.one(id)))
        },
        resetCreate: () => {
            dispatch(resetCreateItem())
        },
        create: form => {
            dispatch(createItem(name, addresses.all, form))
        },
        resetUpdate: () => {
            dispatch(resetUpdateItem())
        },
        update: (id, form) => {
            dispatch(updateItem(addresses.one(id), form))
        },
        delete: item => {
            dispatch(deleteItem(addresses.one(item.id), item))
        },
        cancelDelete: () => {
            dispatch(cancelDeleteItem())
        },
        changeFilter: (field, value) => {
            dispatch(changePageFilter(name, field, value))
        },
        loadPage: doLoadPage,
        initPage: doInitPage,
    }
}
