import {
  GET_CURRENT_FUND,
  GET_CURRENT_FUND_SUCCESS,
  GET_CURRENT_FUND_FAIL,
  GET_SHARE_IN_FUND_FAIL,
  GET_SHARE_IN_FUND_SUCCESS,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAIL,
  FETCH_INVESTORS_SUCCESS,
  FETCH_INVESTORS_FAIL,
  CREATE_NEW_FUND_FAIL,
  CREATE_NEW_FUND_SUCCESS,
  CREATE_NEW_FUND,
  CREATE_NEW_WHITELIST,
  CREATE_NEW_WHITELIST_SUCCESS,
  CREATE_NEW_WHITELIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  currentFund: {
    denominationAssetBalance: 0,
    feeBpsForFund: 0,
    feeBpsForProtocol: 0,
    feeDepositForFund: 0,
    feeDepositForProtocol: 0,
    fundAddress: "",
    name: "",
    owner: "",
    symbol: "",
    totalSupply: 0
  },
  currentManagers: [],
  currentInvestors: [],
  sharesBalance: 0,
  isLoadingCurrentFund: false,
  createFundPending: false,
  createWhitelistPending: false,
  updateWhitelistPending: false,
}

const Fund = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_FUND:
      return {
        ...state,
        isLoadingCurrentFund: true
      }

    case GET_CURRENT_FUND_SUCCESS:
      return {
        ...state,
        currentFund: {
          denominationAssetBalance: action.payload.denominationAssetBalance,
          feeBpsForFund: action.payload.feeBpsForFund,
          feeBpsForProtocol: action.payload.feeBpsForProtocol,
          feeDepositForFund: action.payload.feeDepositForFund,
          feeDepositForProtocol: action.payload.feeDepositForProtocol,
          fundAddress: action.payload.fundAddress,
          name: action.payload.name,
          owner: action.payload.owner,
          symbol: action.payload.symbol,
          totalSupply: action.payload.totalSupply,
        },
        isLoadingCurrentFund: false,
      }

    case GET_CURRENT_FUND_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoadingCurrentFund: false
      }
    
    case GET_SHARE_IN_FUND_SUCCESS:
      return {
        ...state,
        sharesBalance: action.payload,
      }
  
    case GET_SHARE_IN_FUND_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case FETCH_MANAGERS_SUCCESS:
      return {
        ...state,
        currentManagers: action.payload
      }
  
    case FETCH_MANAGERS_FAIL:
      return {
        ...state,
        error: action.payload
      }
    
    case FETCH_INVESTORS_SUCCESS:
      return {
        ...state,
        currentInvestors: action.payload
      }
  
    case FETCH_INVESTORS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case CREATE_NEW_FUND:
      return {
        ...state,
        createFundPending: true
      }

    case CREATE_NEW_FUND_SUCCESS:
      return {
        ...state,
        createFundPending: false,
      }

    case CREATE_NEW_FUND_FAIL:
      return {
        ...state,
        createFundPending: false,
      }

    case CREATE_NEW_WHITELIST:
      return {
        ...state,
        createWhitelistPending: true
      }

    case CREATE_NEW_WHITELIST_SUCCESS:
      return {
        ...state,
        createWhitelistPending: false
      }

    case CREATE_NEW_WHITELIST_FAIL:
      return {
        ...state,
        createWhitelistPending_FAIL: false
      }

    default:
      return state
  }
}

export default Fund
