import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
// create link
import { Link } from 'react-router-dom';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";

//i18n
import { useTranslation } from "react-i18next"

// actions
import { editProfile, getCurrentProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error, user } = useSelector(state => ({
    error: state.Profile.error,
    user: state.Profile.currentUser,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
     
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (
        'undefined' !== typeof process && (
          process?.env?.REACT_APP_DEFAULTAUTH === "fake" ||
          process?.env?.REACT_APP_DEFAULTAUTH === "jwt"
        )
      ) {
        // setname(obj.username);
        // setemail(obj.email);
        // setidx(obj.uid);
        setname("à modifier dans le code");
        setemail("à modifier dans le code");
        setidx("à modifier dans le code");
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
    if(!user || !user.uid) {
      dispatch(getCurrentProfile());
    }
  }, [dispatch, user]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Profile")} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Hesiode" breadcrumbItem={t("Profile")} />

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <div className="">
                    <div className="mb-5">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-xl img-thumbnail rounded-circle position-relative top-0 start-50 translate-middle-x"
                      />
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="list-group list-group-flush ms-4">
                          <h5 className="list-group-item text-primary">{t("Customer.user")}</h5>
                          <p className="list-group-item">{t("Customer.firstname")}:<span className="text-uppercase fw-bold ms-1">{user.firstname}</span></p>
                          <p className="list-group-item">{t("Customer.name")}: <span className="text-uppercase fw-bold ms-1">{user.lastname}</span></p>
                          <p className="list-group-item">{t("Customer.mail")}: <span className="text-uppercase fw-bold ms-1">{user.email}</span></p>
                          <p className="list-group-item">{t("Customer.password")}: <span className="text-uppercase fw-bold ms-1">{user.password ? Array(user.password.length).fill("*") : ""}</span></p>
                          <p className="list-group-item">{t("Customer.phone")}: <span className="text-uppercase fw-bold ms-1">{user.phone}</span></p>
                          <p className="list-group-item">{t("Customer.id")}: <span className="text-uppercase fw-bold">#{user.uid}</span></p>
                        </div>
                      </div>
                      <div className="col-xl-5">
                        <div className="list-group list-group-flush ms-4">
                          <h5  className="list-group-item text-primary">{t("Customer.company")}</h5>
                          <p className="list-group-item">{t("Customer.companyName")}: <span className="text-uppercase fw-bold ms-1">{user.companyName}</span></p>
                          <p className="list-group-item">{t("Customer.companyAddress")}: <span className="text-uppercase fw-bold ms-1">{user.companyAddress}</span></p>
                          <p className="list-group-item">{t("Customer.companyZipCode")}: <span className="text-uppercase fw-bold ms-1">{user.companyZipCode}</span></p>
                          <p className="list-group-item">{t("Customer.companyCity")}: <span className="text-uppercase fw-bold ms-1">{user.companyCity}</span></p>
                          <p className="list-group-item">{t("Customer.companyEmail")}: <span className="text-uppercase fw-bold ms-1">{user.companyEmail}</span></p>
                          <p className="list-group-item">{t("Customer.companyPhone")}: <span className="text-uppercase fw-bold ms-1">{user.companyPhone}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <div className="col text-center mt-2 mb-4">
                  <Link to="/editProfile">
                    <button type="button" className="btn btn-primary">{t("Customer.editProfile")}</button>
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
