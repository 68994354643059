import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./fire_url_helpers"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const postApiLogin = data => post(url.POST_LOGIN, data)

const postApiRegister = data => post(url.POST_REGISTER, data)

const getWallets = (token, filter) => get(url.GET_WALLET, {
  headers: {
      Authorization: `Bearer ${token}`,
  },
})

const getInvestors = (searchValue, token) => get(url.GET_INVESTORS + "?[roles]=ROLE_INVESTOR" + (searchValue ? ("&search=" + encodeURIComponent(searchValue)) : ""), {
  headers: {
    Authorization: `Bearer ${token}`
  }
})

const getInvestor = (id, token) => get(url.GET_INVESTOR(id), {
  headers: {
    Authorization: `Bearer ${token}`
  }
})

const createNewInvestor = (customer, token) => post(url.POST_CREATE_USER, customer, {
  headers: {
    Authorization: `Bearer ${token}`
  }
})

const createNewBlockchainAddress = (address, token) => post(url.POST_CREATE_BLOCKCHAIN_ADDRESS, address, {
  headers: {
    Authorization: `Bearer ${token}`
  }
})


// Me endpoints
const getMeInformation = token => get(url.GET_ME_INFORMATION, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

const getTokensList = () => get(url.GET_TOKENS_LIST)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postApiLogin,
  postApiRegister,
  getWallets,
  getInvestors,
  getInvestor,
  createNewInvestor,
  getMeInformation,
  getTokensList,
  createNewBlockchainAddress,
}
