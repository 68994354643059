/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"
export const GET_CUSTOMER_PROFILE = "GET_CUSTOMER_PROFILE"
export const GET_CUSTOMER_PROFILE_SUCCESS = "GET_CUSTOMER_PROFILE_SUCCESS"
export const GET_CUSTOMER_PROFILE_FAIL = "GET_CUSTOMER_PROFILE_FAIL"

/**
 * add CUSTOMER
 */
export const RESET_ADD_NEW_INVESTOR = "RESET_ADD_NEW_INVESTOR"
export const ADD_NEW_INVESTOR = "ADD_NEW_INVESTOR"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"
