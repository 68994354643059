import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {columnsShape, rowActionsShape} from "../DataTable/shapes"
import {resourceShape} from "../shapes"
import DataTable from "../DataTable"
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {initResourcePage} from "../hooks";

function ResourceDataTable(props) {
    const navigate = useNavigate()
    const page = useSelector(props.resource.selectPage())
    const pageLoading = useSelector(props.resource.selectPageLoading())

    initResourcePage(props.resource, props.initFilters)

    return (
        <DataTable
            id={'resource_datatable_' + props.resource.name}
            striped={props.striped}
            loading={pageLoading}
            pagination={props.pagination}
            thead={props.thead}
            tfoot={props.tfoot}
            columns={props.columns}
            items={page?.items}
            selectedRow={props.selectedRow}
            onRowClick={props.rowClick}
            onRowDoubleClick={
                false !== props.rowDoubleClick ?
                    (typeof props.rowDoubleClick === 'function' ?
                        props.rowDoubleClick :
                        (evt, row) => navigate(props.resource.basePath + '/' + row.id)
                    ) :
                    undefined
            }
            rowActions={[
                ...(props.rowActions || []),
                ...(props.defaultActions === false || props.rowDetails === false ? [] : [{
                    icon: 'mdi mdi-eye',
                    title: 'Details',
                    callback: row => navigate(props.resource.basePath + '/' + row.id),
                }]),
                ...(props.defaultActions === false || props.rowEdit === false ? [] : [{
                    icon: 'bx bx-edit',
                    title: 'Edit',
                    callback: row => navigate(props.resource.basePath + '/' + row.id + '/edit'),
                }]),
                ...(props.defaultActions === false || props.rowDelete === false ? [] : [{
                    icon: 'bx bx-trash',
                    title: 'Delete',
                    callback: props.resource.delete,
                }]),
            ]}
        >
            {props.children}
        </DataTable>
    )
}

ResourceDataTable.propTypes = {
    resource: resourceShape.isRequired,
    striped: PropTypes.bool,
    pagination: PropTypes.bool,
    thead: PropTypes.bool,
    tfoot: PropTypes.bool,
    columns: columnsShape.isRequired,
    rowActions: rowActionsShape,
    defaultActions: PropTypes.bool,
    rowDetails: PropTypes.bool,
    rowEdit: PropTypes.bool,
    rowDelete: PropTypes.bool,
    rowClick: PropTypes.func,
    rowDoubleClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    initFilters: PropTypes.object,
    selectedRow: PropTypes.any,
    children: PropTypes.any,
}

export default ResourceDataTable
