import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent,
    TabPane,
} from "reactstrap"

// datatable related plugins
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table'
  import Table from "components/Common/Table";
  
  //i18n
  import { useTranslation } from "react-i18next"
  
  //redux
  import { useSelector, useDispatch } from "react-redux"
  
  import classnames from "classnames";
  import { isGoodNetworkSelector } from "store/crypto/selector";
  import { getWalletAddress, getCurrentWallet} from "store/actions";



  import { Form, useParams } from "react-router-dom";

  import metamask from "assets/icons/metamask.svg"
  import { connectWalllet } from "store/actions";


  //1)Récupérer l'adresse du portefeuille connecté

const Wallet = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

  

   


   

    const walletReports = [
        { title: t("Wallet.Balance"), iconClass: "bx-money", balance: "2 210", currency: "$" },
        { title: t("Wallet.Rewards"), iconClass: "bx-cake", balance: "140", currency: "$" },
        { title: t("Wallet.Rebt"), iconClass: "bxs-bank", balance: "140", currency: "$" },
      ]



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('WalletDetails')} | Hesiode</title>
                </MetaTags>
                <Container fluid>
                    <div className="mb-2 d-flex justify-content-between align-items-center">
                        <h4>{t('My wallet')}</h4>

                    </div>

                          
          <Row>
            {/* Reports Render */}
            {
                walletReports.map((report, key) => (
                <Col sm="6" xxl="3" key={"_col_" + key}>
                  <Card>
                    <CardBody>
                      <div className="mini-stats-wid">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.balance + report.currency}</h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                        </div>
                        
                    </CardBody>
                  </Card>
                </Col>
                
              ))
            }
          </Row>
         
                </Container>
            </div>
        </React.Fragment>
    )
}



export default Wallet