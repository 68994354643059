import {resourceShape} from "../../components/Crud/shapes";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useSelector} from "react-redux";
import {useEffect} from "react";

function Filters(props) {
    const filters = useSelector(props.resource.selectPageFilters())

    useEffect(() => {
        if (filters?.search_term !== undefined) {
            const timeout = setTimeout(props.resource.loadPage, 300);

            return () => clearTimeout(timeout)
        }
    }, [filters?.search_term])

    return (
        <Row>
            <Col xs={12} sm={6} md={4} xxl={3}>
                <div class="search-box me-2 mb-2 d-inline-block">
                    <div class="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" class="sr-only">Search this table</span>

                            <Input
                                id={"search-bar-0-label"}
                                type={"text"}
                                value={filters?.search_term || ''}
                                onChange={evt => props.resource.changeFilter('search_term', evt.target.value)}
                                className="rounded-pill"
                                placeholder={props.t('Find fund')}
                            />
                        </label>
                        <i class="bx bx-search-alt search-icon"/>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

Filters.propTypes = {
    resource: resourceShape.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(Filters)
