import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    TabContent,
    TabPane,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Input,
    Progress,
    Table,
} from "reactstrap"
import classnames from "classnames";

// datatable related plugins
import {
    createColumnHelper,
  } from '@tanstack/react-table'

// Formik validation
import * as Yup from "yup";
import { useFormik, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import {
    toggleUpdateWhitelistModal,
    apiTokens,
    toggleAddTokenModal
} from "store/actions";
import { ModalFooter } from "react-bootstrap";
import AddTokenButton from "./AddTokenButton";
import { select } from "redux-saga/effects";
import { selectAccessToken } from "store/auth/login/selector";

const UpdateWhitelistModal = () => {
    const dispatch = useDispatch();
    const [selectToken, setSelectToken] = useState();

    useEffect(() => {
        dispatch(apiTokens())
    }, [])

    const { isUpdateWhitelistModalOpen, isAddTokenModalOpen, tokens } = useSelector((state) => ({
        isUpdateWhitelistModalOpen: state.Layout.modalUpdateWhitelist.isOpen,
        isAddTokenModalOpen: state.Layout.modalAddToken.isOpen,
        tokens: state.crypto.tokenList
    }))

    const toggleModal = (isOpen) => {
        (isOpen !== isUpdateWhitelistModalOpen ) && dispatch(toggleUpdateWhitelistModal(!isUpdateWhitelistModalOpen))
    }

    const toggleTokenModal = (isOpen) => {
        (isOpen !== isAddTokenModalOpen ) && dispatch(toggleAddTokenModal(!isAddTokenModalOpen))
    }

    const divStyle = {
        height: 390,
        'willChange': 'transform',
        direction: 'ltr',
    };

    function selectAccessToken(address) {
        setSelectToken(address);
    }

    return (
        <Modal centered={true} isOpen={isUpdateWhitelistModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    Modifier la whitelist : test
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h5>liste des tokens</h5>
                        <AddTokenButton />
                    </div>
                        <Modal centered={true} isOpen={isAddTokenModalOpen} toggle={toggleTokenModal} >
                            <ModalHeader toggle={toggleTokenModal}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <h5>Ajouter un token</h5>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <form className="d-flex flex-column">
                                    <label className="h6">
                                        Rechercher un token
                                    </label>
                                    <input className="mb-4 form-control form-control-lg" type="text" name="Symbole du token" placeholder="Symbole du token"/>
                                </form>
                                <div className="form-control p-0 position-relative overflow-auto" style={divStyle}>
                                    {tokens && tokens.length > 0 ?
                                        tokens.map((token, index) => 
                                            <div className={ token.address == selectToken ? "d-flex px-2 align-items-center justify-content-start list-line list-line-selected" : "d-flex px-2 align-items-center justify-content-start list-line"}
                                            key={index}
                                            onClick={() => selectAccessToken(token.address)}>
                                                <img style={{width: '30px', height: '30px'}} src={token.logoURI}></img>
                                                <div className="mx-5">
                                                    <div className="fw-bold fs-5">{token.symbol}</div>
                                                    <div className="text-secondary">{token.name}</div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <div className="p-4">Aucun tokens trouvé</div>
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => toggleAddTokenModal}>
                                    Annuler
                                </Button>
                                <Button color="primary" onClick={() => toggleAddTokenModal}>
                                    Ajouter
                                </Button>
                            </ModalFooter>
                        </Modal>
                <div className="form-control p-0 position-relative overflow-auto" style={divStyle}>
                    <div className="d-flex px-2 align-items-center justify-content-between list-line">       
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="ms-2">
                                <div className="fw-bold fs-5"></div>
                                <div className="text-secondary"></div>
                            </div>
                        </div>
                        <div>
                            <Button color="primary">Supprimer</Button>
                        </div>
                    </div>
                </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal}>
                        fermer
                    </Button>
                </ModalFooter>
        </Modal>
    )
}

export default UpdateWhitelistModal