import PropTypes from "prop-types";

function VLCell(props) {
    if (props.value === 0 || props.value === '0') {
        return '-'
    }

    const rate = 100 * (parseFloat(props.lastValue) - parseFloat(props.value) / parseFloat(props.value))

    return <div style={{color: rate > 0 ? 'green' : 'red'}}>{`${Math.round(10 * rate) / 10}%`}</div>
}

VLCell.propTypes = {
    lastValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default VLCell
