import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// layouts Format
import AuthLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import PrivateRoutes from 'routes/PrivateRoutes'
import { setWeb3Datas } from 'store/actions'
import TransactionOkModal from 'components/Common/TransactionOkModal'
import CreateFundModal from 'components/web3/CreateFundModal'

// Activating fake backend
fakeBackend()

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setWeb3Datas())
  }, [])

  useEffect(() => {
    const { ethereum } = window
    if (ethereum) {
      ethereum.on('chainChanged', () => dispatch(setWeb3Datas()));
      ethereum.on('accountsChanged', () => dispatch(setWeb3Datas()))
    }
  }, [])
  
  return (
    <>
    <TransactionOkModal />
    <CreateFundModal />

    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes isAuthProtected={true}/>}>
            {
              authProtectedRoutes.map((route, idx) => {
                return <Route key={idx} path={route.path} element={<AuthLayout>{route.component}</AuthLayout>} />
              })
            }
        </Route>
        {
          publicRoutes.map((route, idx) => {
            return <Route key={idx} path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} />
          })
        }
      </Routes>
    </BrowserRouter>
    </>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
