import { ethers } from 'ethers'
import { createSelector } from 'reselect'
import { formatToken } from 'utils';

export const getFundBalanceEth = createSelector(
    (state) => state.Fund.currentFund.denominationAssetBalance,
    (balance) => {
        return ethers.utils.formatEther(balance);
    }
)

export const getFormatedSharesBalance = createSelector(
    (state) => state.Fund.sharesBalance,
    (sharesBalance) => formatToken(sharesBalance, 6)
)

export const getFormatedTotalSupply = createSelector(
    (state) => state.Fund.currentFund.totalSupply,
    (totalSupply) => formatToken(totalSupply, 18)
)