import {ADD_TOAST, REMOVE_TOAST} from "./actionTypes";

export function addToast(title, body, color, icon, timeout) {
    return {
        type: ADD_TOAST,
        payload: {title, body, color, icon, timeout},
    }
}

export function removeToast(toast) {
    return {
        type: REMOVE_TOAST,
        payload: toast,
    }
}
