import {
    ADD_BLOCKCHAIN_ADDRESS_SUCCESS,
    ADD_BLOCKCHAIN_ADDRESS_FAIL,
    ADD_NEW_BLOCKCHAIN_ADDRESS,
} from "./actionTypes"

const INIT_STATE = {
    error: null,
    addBlockchainAddress: null,
    loading: false,
    blockchainAddresses: [],
    currentBlockchainAddress: {
        events: []
    }
}

const BlockchainAddress = (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case ADD_NEW_BLOCKCHAIN_ADDRESS:
            return {
                ...state,
                loading: true,
            }

        case ADD_BLOCKCHAIN_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                addBlockchainAddress: action.payload,
                blockchainAddresses: [...state.blockchainAddresses, action.payload],
            }

        case ADD_BLOCKCHAIN_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

       
        default:
            return state
    }
}

export default BlockchainAddress