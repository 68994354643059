/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

// OK MODAL
export const TOGGLE_OK_MODAL = "TOGGLE_OK_MODAL"

// New Fund Modal
export const TOGGLE_NEW_FUND_MODAL = "TOGGLE_NEW_FUND_MODAL"

// New Whitelist Modal
export const TOGGLE_NEW_WHITELIST_MODAL = "TOGGLE_NEW_WHITELIST_MODAL"

// Update Whistelist Modal
export const TOGGLE_UPDATE_WHITELIST_MODAL = "TOGGLE_UPDATE_WHITELIST_MODAL"

// Add Token Modal
export const TOGGLE_ADD_TOKEN_MODAL = "TOGGLE_ADD_TOKEN_MODAL"
