import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Input,
} from "reactstrap"

//i18n
import { useTranslation } from "react-i18next"
// Formik validation
import * as Yup from "yup";
import { useFormik, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import {
    createNewWhitelist,
    toggleNewWhitelistModal
} from "store/actions";

const CreateWhitelistModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { isNewWhitelistModalOpen } = useSelector((state) => ({
        isNewWhitelistModalOpen: state.Layout.modalNewWhitelist.isOpen,
    }))

    const toggleModal = (isOpen) => {
        (isOpen !== isNewWhitelistModalOpen ) && dispatch(toggleNewWhitelistModal(!isNewWhitelistModalOpen))
    };

    const initialValues = {
        name: ""
    }

    const validationSchema = Yup.object({
        name: Yup.string().required(t("Required last name")),
    })

    const submitFunction = ({ name }, actions) => {
        dispatch(createNewWhitelist(name))
        actions.setSubmitting(false)
    }

    return (
        <>
        <Button outline color="primary" onClick={() => toggleModal(true)}>
            <i className="fas fa-plus-circle font-size-16 align-middle me-1"></i>
            Créer une Whitelist
        </Button>

        <Modal centered={true} isOpen={isNewWhitelistModalOpen} toggle={toggleModal}>
            <Formik
                initialValues={initialValues}
                onSubmit={submitFunction}
                validationSchema={validationSchema}
            >
            { props =>
            <Form onSubmit={props.handleSubmit}>
                <ModalHeader toggle={toggleModal}>
                    Créer une whitelist
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">
                            {t("Name")}
                        </Label>
                        <Input
                            id="name"
                            name="name"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name || ""}
                            invalid={
                                props.touched.name && props.errors.name ? true : false
                            }
                        />
                        {props.touched.name && props.errors.name ? (
                            <FormFeedback type="invalid">{props.errors.name}</FormFeedback>
                        ) : null}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal}>
                        {t("Cancel")}
                    </Button>
                    <Button type="submit" color="primary">
                        {t("Create")}
                    </Button>
                </ModalFooter>
            </Form>
            }
            </Formik>
        </Modal>
        </>
    )
}

export default CreateWhitelistModal