import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"

//i18n
import { useTranslation } from "react-i18next"

import { chain } from "mathjs"
import { useDispatch, useSelector } from "react-redux";

import metamask from "assets/icons/metamask.svg"
import { connectWalllet } from "store/actions";
import { getActiveBalanceEth, isGoodNetworkSelector } from "store/crypto/selector";
import MetaMaskAvatar from "components/Common/MetaMaskAvatar";
  
const ConnectWalletModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const isGoodNetwork = useSelector(isGoodNetworkSelector)
    const activeBalanceEth = useSelector(getActiveBalanceEth)
    const { isWalletConnected, activeAccount, isLoadingWallet } = useSelector((state) => ({
      isWalletConnected: state.crypto.isWalletConnected,
      activeAccount: state.crypto.activeAccount,
      isLoadingWallet: state.crypto.isLoadingWallet
    }))

    const formatAddress = address => {
        if (address && address.length === 42) {
            const begin =  address.slice(0, 6)
            const end = address.slice(-4)
            return begin + "..." + end
        }
        return "";
    }

    const formatUSDC = balance => {
        if (balance > 0) {
            return chain(balance.toString()).divide(10**6).done()
        }
        return 0
    }

    const formatedBalance = balance => {
        if (balance.indexOf(".") !== -1) {
            const [begin, end] = balance.split(".")
            return begin + "." + end.slice(0,3)
        }
        return balance
    }

    if (isLoadingWallet) {
        return (
            <div>
                <div className="d-flex align-items-center justify-content-between rounded-2 bg-light">
                    <span className="py-2 px-3">
                        <b className="d-flex gap-1">
                            ...
                        </b>
                    </span>
                    <div className="btn btn-primary">
                        <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only">{t("Loading")}...</span></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
            {
            isWalletConnected ?
                <div className="d-flex align-items-center justify-content-between rounded-2 bg-light">
                    {
                    isGoodNetwork ? 
                        <>
                        <span className="py-2 px-3">
                            <b className="d-flex gap-1">
                                {formatedBalance(activeBalanceEth)}
                                <span className="d-none d-md-block">MATIC</span>
                            </b>
                        </span>
                        <Button color="primary" outline onClick={toggleModal}>
                            <span className="d-none d-sm-block" >{formatAddress(activeAccount)}</span>
                            <span className="d-sm-none">
                                <MetaMaskAvatar account={"0xb59b3aaE4a67906A76B0868B9a65D4719feEA551"} size="20" />
                            </span>
                        </Button>
                        </>
                    :
                        <>
                        <span className="px-3">
                            <i className="font-size-16 fas fa-exclamation-triangle text-black"></i>
                        </span>
                        <Button color="warning">
                            {t("Bad network")}
                        </Button>
                        </>
                    }
                </div>
            :
                <Button color="primary" className="w-100" onClick={toggleModal}>
                    {t("Connect Wallet")}
                </Button>
            }

                <Modal centered={true} isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        { isWalletConnected ? t("Manage Wallet") : t("Connect Wallet") }
                    </ModalHeader>
                    <ModalBody>
                    {
                        isWalletConnected ?
                            <>
                                <p> {t('Connected with Metamask')}</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center rounded-2 bg-light">
                                        {
                                        isGoodNetwork ?
                                            <>
                                            <div className="px-3"><b>{formatedBalance(activeBalanceEth)}</b></div>
                                            <div className="p-2 bg-primary text-white rounded-2">{formatAddress(activeAccount)}</div>
                                            </>
                                        :
                                            <>
                                            <div className="px-3">
                                                <i className="font-size-16 fas fa-exclamation-triangle text-black"></i>
                                            </div>
                                            <div className="p-2 bg-warning text-white rounded-2">label.wrongNetwork</div>
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                !isGoodNetwork &&
                                    <div className="d-flex bg-light p-3 rounded-2">
                                        <div className="pe-2">
                                            <i className="font-size-16 fas fa-exclamation-triangle text-black"></i>
                                        </div>
                                        <div>
                                            <div className="text-warning"><b>label.wrongNetwork</b></div>
                                            <p className="text-warning">{t("Please login to Polygon")}</p>
                                            <Button variant="primary"
                                                // onClick="changeNetwork"
                                            >{t("Change network")}</Button>
                                        </div>
                                    </div>
                                }
                            </>
                        :
                            <div className="text-center">
                                <div className="mb-4 px-4">
                                    <Button
                                        color="primary"
                                        outline size="lg"
                                        className="d-block w-100"
                                        onClick={() => dispatch(connectWalllet())}
                                    >
                                        <span className="d-flex justify-content-between align-items-center">
                                            Metamask
                                            <img src={metamask} alt="Metamask" height="34" />
                                        </span>
                                    </Button>
                                </div>
                                <div>
                                {t("New to Ethereum")} <a href="https://ethereum.org/en/wallets/">{t("About Wallets")}</a>
                                </div>
                            </div>
                    }
                    </ModalBody>
                    {
                    isWalletConnected ?
                        <ModalFooter>
                            <div className="w-100">
                                <Row className="mb-2 align-items-end">
                                    <Col className="fw-medium">{t("Administrator")}</Col>
                                    <Col className="fw-medium text-end">{formatUSDC(0)} {t("AssetBalance")}</Col>
                                </Row>
                                <div>
                                    <Button color="primary">
                                        {t("Recover taxes")}
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    :
                        null
                    }
                </Modal>
            </div>
        )
    }
}

export default ConnectWalletModal